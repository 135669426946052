body,
input,
pre,
code,
kbd,
samp,
dt,
#lang_sel_footer ul li a {
  font-size: 14px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.desk .header-top-menu ul li > a .txt {
font-family: 'Lato', sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  text-transform: none;
}
h1 {
  font-size: 24px;
  line-height: 34px;
}
h2 {
  font-size: 22px;
  line-height: 32px;
}
h3 {
  font-size: 20px;
  line-height: 30px;
}
h4 {
  font-size: 18px;
  line-height: 28px;
}
h5 {
  font-size: 16px;
  line-height: 26px;
}
h6 {
  font-size: 14px;
  line-height: 24px;
}
.ls a,
.mob-menu li a,
#nz-content .search input[type="text"],
.mob-header-content .header-top-menu li a,
.mob-header-content .top-button,
.header-top .top-button,
.widget_title,
.yawp_wim_title,
textarea,
select,
button,
.button,
.button .txt,
input[type="month"],
input[type="number"],
input[type="submit"],
input[type="button"],
input[type="date"],
input[type="datetime"],
input[type="password"],
input[type="search"],
input[type="datetime-local"],
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="reset"],
.nz-timer,
.count-value,
.nz-persons .person .name,
.nz-pricing-table > .column .title,
.nz-pricing-table .price,
.tabset .tab,
.toggle-title .toggle-title-header,
.ninzio-navigation,
.woocommerce-pagination,
.ninzio-filter .filter,
.single-details .nz-i-list a,
.nz-table th,
.comment-meta .comment-author cite,
.wp-caption .wp-caption-text,
.woocommerce .product .added_to_cart,
.woocommerce-tabs .tabs > li,
.woocommerce .single-product-summary .amount,
.reset_variations,
.footer-menu > ul > li > a,
.share-labelf,
a.edit,
a.view,
.widget_icl_lang_sel_widget,
.nz-progress .progress-percent,
.nz-progress .progress-title,
.nz-content-box-2 .box-title span,
.footer-info,
.product .price,
.related-products-title h3,
.ninzio-nav-single > *,
.error404-big,
.box-more,
.count-title,
.nz-pricing-table .hlabel,
.widget_nz_recent_entries .post .post-date-custom,
.standard .post-date-custom,
.status-author,
.quote-author,
.link-link,
.single .post-social-share .social-links a span,
.woocommerce .product .onsale,
.widget_tag_cloud .tagcloud a,
.post-tags a,
.widget_product_tag_cloud .tagcloud a,
.projects-tags a,
.widget_recent_entries a,
.widget_categories ul li a,
.widget_pages ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_nav_menu li a,
.widget_product_categories li a,
.post .post-category ul li a,
.tagline-title,
.nz-accordion .toggle-title {
  font-family: Arial, Helvetica, sans-serif !important;
}
html,
#gen-wrap {
  background-color: #ffffff;
}
.shoping-cart .rich-header,
.woo-account .rich-header,
.shoping-cart .parallax-container,
.woo-account .parallax-container,
.shoping-cart .fixed-container,
.woo-account .fixed-container {
}
.shoping-cart .rich-header h1,
.woo-account .rich-header h1 {
  color: ;
}
.shoping-cart .rich-header .nz-breadcrumbs,
.woo-account .rich-header .nz-breadcrumbs {
  color: #ffffff;
}
.footer {
  background-color: #2d2e2f !important;
}
::-moz-selection {
  background-color: #ffb900;
  color: #ffffff;
}
::selection {
  background-color: #ffb900;
  color: #ffffff;
}
.mob-menu li a:hover,
.mob-menu .current-menu-item > a,
.mob-menu .current-menu-parent > a,
.mob-menu .current-menu-ancestor > a,
.mob-menu ul li > a:hover > .di,
.mob-menu .current-menu-item > a > .di,
.mob-menu .current-menu-parent > a > .di,
.mob-menu .current-menu-ancestor > a > .di,
.mob-header-content .header-top-menu li a:hover,
.mob-header-content .header-top-menu .current-menu-item > a,
.mob-header-content .header-top-menu .current-menu-parent > a,
.mob-header-content .header-top-menu .current-menu-ancestor > a,
.mob-header-content .header-top-menu ul li > a:hover > .di,
.mob-header-content .header-top-menu .current-menu-item > a > .di,
.mob-header-content .header-top-menu .current-menu-parent > a > .di,
.mob-header-content .header-top-menu .current-menu-ancestor > a > .di,
.search-r .post-title a,
.project-category a:hover,
.project-details a:hover,
.nz-related-portfolio .project-details a:hover,
.single-details .nz-i-list a:hover,
.blog-post .post .post-title:hover > a,
.nz-recent-posts .post .post-title:hover > a,
.blog-post .post .post-meta a:hover,
.nz-recent-posts .post .post-meta a:hover,
.blog-post .post .post-meta a:hover i,
.nz-recent-posts .post .post-meta a:hover i,
.woocommerce .product .price,
.single-post-content a:not(.button),
.nz-breadcrumbs a:hover,
.error404-big,
.nz-content-box-2 a:hover .box-title h3,
.nz-breadcrumbs > *:before,
.post-comments a:hover,
.footer-menu ul li a:hover,
.comment-content .edit-link a:hover,
.loop .project-title a:hover,
.loop .projects-category a:hover,
.nz-related-projects .projects-category a:hover,
.nz-recent-projects .projects-category a:hover,
.widget_product_categories ul li a:hover,
.widget_nav_menu ul li a:hover,
.widget_shopping_cart p.buttons > a:hover,
.widget_recent_entries a:hover,
.widget_categories ul li a:hover,
.widget_pages ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_nz_recent_entries .post-title:hover,
.widget_price_filter .price_slider_amount .button:hover,
.sidebar .widget_shopping_cart .cart_list > li > a:hover,
.sidebar .widget_products .product_list_widget > li > a:hover,
.sidebar .widget_recently_viewed_products .product_list_widget > li > a:hover,
.sidebar .widget_recent_reviews .product_list_widget > li > a:hover,
.sidebar .widget_top_rated_products .product_list_widget > li > a:hover,
.sidebar .widget_product_tag_cloud .tagcloud a:hover,
.sidebar .post-tags a:hover,
.sidebar .projects-tags a:hover,
.sidebar .widget_tag_cloud .tagcloud a:hover,
.sidebar .widget_recent_entries a:hover,
.sidebar .widget_nz_recent_entries .post-title:hover,
.sidebar .widget_product_categories ul li a:hover,
.sidebar .widget_nav_menu ul li a:hover,
.sidebar .widget_categories ul li a:hover,
.sidebar .widget_pages ul li a:hover,
.sidebar .widget_archive ul li a:hover,
.sidebar .widget_meta ul li a:hover,
.site-widget-area .widget_shopping_cart .cart_list > li > a:hover,
.site-widget-area .widget_products .product_list_widget > li > a:hover,
.site-widget-area
  .widget_recently_viewed_products
  .product_list_widget
  > li
  > a:hover,
.site-widget-area .widget_recent_reviews .product_list_widget > li > a:hover,
.site-widget-area
  .widget_top_rated_products
  .product_list_widget
  > li
  > a:hover,
.site-widget-area .widget_product_tag_cloud .tagcloud a:hover,
.site-widget-area .post-tags a:hover,
.site-widget-area .projects-tags a:hover,
.site-widget-area .widget_tag_cloud .tagcloud a:hover,
.footer-widget-area .widget_shopping_cart .cart_list > li > a:hover,
.footer-widget-area .widget_products .product_list_widget > li > a:hover,
.footer-widget-area
  .widget_recently_viewed_products
  .product_list_widget
  > li
  > a:hover,
.footer-widget-area .widget_recent_reviews .product_list_widget > li > a:hover,
.footer-widget-area
  .widget_top_rated_products
  .product_list_widget
  > li
  > a:hover,
.footer-widget-area .widget_product_tag_cloud .tagcloud a:hover,
.footer-widget-area .post-tags a:hover,
.footer-widget-area .projects-tags a:hover,
.footer-widget-area .widget_tag_cloud .tagcloud a:hover,
.mob-header .widget_shopping_cart p.buttons > a:hover,
.mob-header .widget_price_filter .price_slider_amount .button:hover,
.blog-post .post .postmeta a:hover,
.loop .small-image .projects .projects-category a:hover,
.loop .medium-image .projects .projects-category a:hover,
.loop .large-image .projects .projects-category a:hover,
.loop .small-image-nogap .projects .projects-category a:hover,
.loop .medium-image-nogap .projects .projects-category a:hover,
.nz-recent-projects.small-image .projects-category a:hover,
.nz-recent-projects.medium-image .projects-category a:hover,
.nz-recent-projects.large-image .projects-category a:hover,
.nz-recent-projects.small-image-nogap .projects-category a:hover,
.nz-recent-projects.medium-image-nogap .projects-category a:hover,
.nz-related-projects .projects-category a:hover,
.project-social-link-share .social-links a:hover,
.post-author-info .author-social-links a:hover,
.box-more:hover,
.nz-persons .person .name:hover,
.stepset .active .title,
.quote-author,
.status-author,
.link-link,
.post-social-share .social-links a:hover,
.nz-pricing-table .price,
.nz-pricing-table .currency,
.post .post-more:hover,
.woocommerce .product .button:hover,
.woocommerce .product .added_to_cart:hover,
.woocommerce .product .product_type_external:hover,
.single .post-social-share .social-links a:hover,
.desk .header-top .header-top-social-links a:hover,
.version3 .header-social-links a:hover {
  color: #ffb900 !important;
}
#nz-content a:not(.button),
#nz-content a:not(.button):visited,
.sidebar a:not(.button):not(.ui-slider-handle),
.woo-cart .widget_shopping_cart .cart_list > li > a:hover,
.woocommerce .single-product-summary .product_meta a:hover,
.reset_variations:hover,
.count-icon,
.post-comments-area .comments-title,
.footer-copyright a,
.post-comments-area a,
.post-author-info-title a,
.post-tags a:hover,
.shop-loader,
.woocommerce .star-rating,
.woocommerce-page .woocommerce-product-rating .star-rating,
.woocommerce .comment-text .star-rating,
.widget_recent_reviews .star-rating,
.widget_top_rated_products .star-rating,
.woocommerce-tabs .comment-form-rating .stars a {
  color: #ffb900;
}
#nz-content a:not(.button):hover,
#nz-content a:not(.button):visited:hover,
.woocommerce .product_meta a:hover,
.sidebar a:not(.button):hover,
.post-author-info-title a:hover {
  color: rgb(205, 135, -50);
}
.flex-control-paging li a.flex-active,
.flex-direction-nav a:hover,
.ninzio-navigation li a:hover,
.ninzio-navigation li .current,
.woocommerce-pagination li a:hover,
.woocommerce-pagination li span.current,
.widget_price_filter .ui-slider .ui-slider-range,
.nz-persons .person .title:after,
.post .post-date:after,
#nz-content .nz-single-image:before,
.widget_twitter .follow,
.nz-content-box-2 .box-title h3:after,
.nz-content-box-2 .box-title h3:before,
.nz-persons .person .title:after,
.nz-persons .person .title:before,
.header-top .header-top-menu ul .submenu-languages li a:after,
.mob-menu-toggle2:hover,
#top,
.widget_calendar td#today,
.desk-menu .sub-menu li > a:before,
.standard .post-date-custom,
.widget_title:after,
.yawp_wim_widget:after,
.widget_nav_menu li a:before,
.widget_product_categories li a:before,
.widget_categories ul li a:before,
.widget_pages ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before,
.widget_shopping_cart .cart_list .remove:hover,
.footer-menu ul li a:after,
.post .post-category ul li a,
.single .single-post-content a:before,
.nz-reletated-posts-sep:after,
.post-comments-area .comments-title:after,
.post-comments-area #respond #reply-title:after,
.comment-meta .replay a:hover,
.post-single-navigation a:hover,
.single-projects .post-gallery .flex-direction-nav li a:hover,
.single .project-details a:after,
.nz-related-projects h3:after,
.social-links a:hover,
.box-more:hover:after,
.nz-persons .person .name:after,
.stepset .active .step-title,
.widget_nz_recent_entries .post-body:before,
.widget_twitter ul li:before,
.widget_recent_comments ul li:before,
.post .post-more:hover:after,
.nz-overlay-before:hover,
#nz-content .nz-single-image:hover:before,
.post .post-date-custom,
.ninzio-filter .filter.active,
.ninzio-filter .filter:hover,
.woocommerce .product .button:hover:before,
.woocommerce .product .added_to_cart:hover:before,
.woocommerce .product .product_type_external:hover:before {
  background-color: #ffb900 !important;
}
button,
input[type="reset"],
input[type="submit"],
input[type="button"],
.single-details .project-link,
.wc-proceed-to-checkout a,
.woocommerce-message .button.wc-forward,
a.edit,
a.view,
#nz-content .nz-single-image:before,
.loop .project-more:hover,
.nz-recent-projects .project-more:hover,
.woocommerce .product .onsale,
.woocommerce .single-product-summary .product_meta a:after,
.woocommerce-tabs .panel > h2:after,
.woocommerce-tabs .panel > #reviews > #comments > h2:after,
.related-products-title h3:after,
.button-ghost.default.icon-true.animate-false .btn-icon:after,
.widget_nav_menu li a:before,
.widget_product_categories li a:before,
.widget_categories ul li a:before,
.widget_pages ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before,
#comments #submit:hover,
.ajax-loading-wrap .load-bar:before,
.nz-pricing-table > .column.highlight-true .pricing,
.nz-accordion .toggle-title.active .toggle-icon:before,
.nz-accordion .toggle-title.active .toggle-icon:after,
.owl-controls .owl-page.active,
.button.wc-backward {
  background-color: #ffb900;
}
button:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
.single-details .project-link:hover,
.woocommerce .single-product-summary .button:hover,
.wc-proceed-to-checkout a:hover,
.woocommerce-message .button.wc-forward:hover,
.single_add_to_cart_button:hover,
a.edit:hover,
a.view:hover,
.button-normal.default.icon-true.animate-false .btn-icon:after,
.button-3d.default.icon-true.animate-false .btn-icon:after,
.button-ghost.default.icon-true.animate-false:hover .btn-icon:after,
.button.wc-backward:hover {
  background-color: rgb(235, 165, -20);
}
.button-normal.default.icon-true.animate-false:hover .btn-icon:after {
  background-color: rgb(215, 145, -40);
}
#projects-load-more .project-loader:before {
  border-left: 2px solid #ffb900;
}
.widget_recent_projects .ninzio-overlay {
  background-color: rgba(255, 185, 0, 0.9);
}
.nz-pricing-table > .column .title:after {
  border-bottom: 3em solid #ffb900;
}
.sidebar .widget_tag_cloud .tagcloud a:hover,
.sidebar .post-tags a:hover,
.sidebar .widget_product_tag_cloud .tagcloud a:hover,
.sidebar .projects-tags a:hover,
.footer-widget-area .widget_tag_cloud .tagcloud a:hover,
.footer-widget-area .post-tags a:hover,
.footer-widget-area .widget_product_tag_cloud .tagcloud a:hover,
.footer-widget-area .projects-tags a:hover,
.footer-widget-area .widget_shopping_cart p.buttons > a:hover,
.footer-widget-area .widget_price_filter .price_slider_amount .button:hover,
.comment-meta .replay a:hover,
.single .post-tags > *:hover {
  box-shadow: inset 0 0 0 2px #ffb900;
}
.social-links a:hover {
  box-shadow: inset 0 0 0 1px #ffb900 !important;
}
#single-product-carousel .slides li.flex-active-slide:after {
  box-shadow: inset 0 0 0 2px #ffb900 !important;
}
.slick-dots li.slick-active button {
  box-shadow: inset 0 0 0 20px #ffb900 !important;
}
.shop-loader:before {
  border-left: 2px solid #ffb900;
}
.nz-loader {
  border-top: 1px solid rgba(255, 185, 0, 0.1);
  border-right: 1px solid rgba(255, 185, 0, 0.1);
  border-bottom: 1px solid rgba(255, 185, 0, 0.1);
  border-left: 1px solid rgba(255, 185, 0, 0.5);
}
.widget_price_filter .ui-slider .ui-slider-handle,
.widget_tag_cloud .tagcloud a:after,
.post-tags a:after,
.widget_product_tag_cloud .tagcloud a:after,
.projects-tags a:after,
.widget_tag_cloud .tagcloud a:before,
.post-tags a:before,
.widget_product_tag_cloud .tagcloud a:before,
.projects-tags a:before,
.widget_shopping_cart p.buttons > a:before,
.widget_shopping_cart p.buttons > a:after,
.widget_price_filter .price_slider_amount .button:before,
.widget_price_filter .price_slider_amount .button:after {
  border-color: #ffb900 !important;
}
.single-details .nz-i-list span.icon {
  box-shadow: inset 0 0 0 20px #ffb900;
}
.nz-pricing-table > .column.highlight-true .column-inner {
  box-shadow: inset 0 0 0 3px #ffb900;
}
.nz-pricing-table > .column.highlight-true .c-foot a {
  background-color: #ffb900 !important;
  box-shadow: inset 0 0 0 2px #ffb900;
}
.nz-pricing-table > .column.highlight-true .c-foot a:hover {
  background-color: rgb(235, 165, -20) !important;
  box-shadow: inset 0 0 0 2px rgb(235, 165, -20) !important;
}
.default.button-normal {
  background-color: #ffb900;
}
.default.button-ghost {
  box-shadow: inset 0 0 0 2px #ffb900;
  color: #ffb900;
}
.default.button-3d {
  background-color: #ffb900;
  box-shadow: 0 4px rgb(205, 135, -50);
}
.default.animate-false.button-3d:hover {
  box-shadow: 0 2px rgb(205, 135, -50);
}
.default.animate-false.button-normal.hover-fill:hover {
  background-color: rgb(235, 165, -20);
}
.default.button-ghost.hover-fill:hover,
.default.button-ghost.hover-drop:after,
.default.button-ghost.hover-side:after,
.default.button-ghost.hover-scene:after,
.default.button-ghost.hover-screen:after {
  background-color: #ffb900;
}
.rich-header.version1 .rh-content h1:before {
  border-left-color: #ffb900;
  border-right-color: #ffb900;
}
.mob-header-top {
  height: 100px !important;
  line-height: 100px !important;
}
.mob-header-top .mob-menu-toggle,
.mob-sidebar-toggle {
  top: 35px !important;
}
.version1 .header-top {
  background-color: rgba(106, 72, 72, 1);
}
.version1 .header-top .header-top-menu ul li a,
.version1 .header-top .social-text {
  color: #999999;
  font-weight: normal;
  font-size: 14px;
  text-transform: none;
}
.version1 .header-top .header-top-menu ul li:hover > a {
  color: #ffb700;
}
.version1 .header-top .header-top-menu > ul > li:not(:last-child):after {
  color: rgba(153, 153, 153, 0.3);
}
.version1 .header-top .top-button {
  color: #ffffff;
  background-color: #ffb700;
}
.version1 .header-top .top-button:hover {
  background-color: rgb(235, 163, -20);
}
.version1 .header-top .header-top-social-links a {
  color: #999999 !important;
}
.version1 .header-top .header-top-menu ul li ul.submenu-languages,
.version1 .desk-menu > ul > li ul.submenu-languages {
  width: 149px;
}
.version1.top-false {
  height: 90px;
}
.version1.top-true {
  height: 130px;
}
.version1 .header-body {
  background-color: rgba(255, 255, 255, 1);
  height: 90px;
}
.version1 .logo,
.version1 .logo-title {
  height: 90px;
  line-height: 90px;
}
.version1 .desk-menu .sub-menu {
  top: 90px;
}
.version1 .search {
  top: 65px;
}
.version1 .desk-cart-wrap {
  height: 90px;
}
.version1 .search-toggle-wrap,
.version1 .desk-cart-toggle,
.version1 .site-sidebar-toggle {
  margin-top: 25px;
}
.version1 .site-sidebar-toggle {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent;
}
.version1 .woo-cart {
  top: 90px;
}
.version1 .desk-menu > ul > li {
  margin-left: 20px !important;
  height: 90px;
  line-height: 90px;
}
.version1:not(.active) .logo-title {
  color: #999999;
}
.version1 .desk-menu > ul > li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 30px;
}
.version1 .desk-cart-toggle span {
  color: #999999;
  margin-left: -11px;
}
.version1 .desk-menu > ul > li.menu-item-language > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.5);
}
.version1 .desk-menu > ul > li.menu-item-language:hover > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.7);
}
.version1 .desk-menu > ul > li:hover > a,
.version1 .desk-menu > ul > li.one-page-active > a,
.version1 .desk-menu > ul > li.current-menu-item > a,
.version1 .desk-menu > ul > li.current-menu-parent > a,
.version1 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version1 .desk-menu > ul > li.current-menu-item > a,
.one-page-top.version1 .desk-menu > ul > li.current-menu-parent > a,
.one-page-top.version1 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version1 .desk-menu > ul > li:hover > a,
.one-page-top.version1 .desk-menu > ul > li.one-page-active > a {
  color: #999999 !important;
}
.version1 .desk-menu .sub-menu,
.version1 .header-top .header-top-menu ul li ul,
.version1 .woo-cart {
  background-color: #292929;
}
.version1 .search {
  background-color: #292929 !important;
}
.version1 .desk-menu .sub-menu .sub-menu {
  background-color: rgb(21, 21, 21);
}
.version1 .desk-menu .sub-menu li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version1 .header-top .header-top-menu ul li ul li a {
  color: #999999;
}
.version1 .desk-menu .sub-menu li:hover > a,
.version1 .header-top .header-top-menu ul li ul li:hover > a {
  color: #e2e2e2;
}
.version1 .desk-menu [data-mm="true"] > .sub-menu > li > a {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version1
  .desk-menu
  > ul
  > [data-mm="true"]
  > .sub-menu
  > li:not(:last-child):after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.version1 .desk-menu .sub-menu .label {
  font-family: Arial, Helvetica, sans-serif;
}
.version1 .search-true.cart-false .search-toggle:after,
.version1 .cart-true .desk-cart-wrap:after {
  background-color: rgba(153, 153, 153, 0.2);
}
.version1 .search input[type="text"] {
  color: #999999;
}
.version1.effect-underline .desk-menu > ul > li > a:after,
.version1.effect-overline .desk-menu > ul > li > a:after,
.version1.effect-fill .desk-menu > ul > li:hover,
.version1.effect-fill .desk-menu > ul > li.one-page-active > a,
.version1.effect-fill .desk-menu > ul > li.current-menu-item,
.version1.effect-fill .desk-menu > ul > li.current-menu-parent,
.version1.effect-fill .desk-menu > ul > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version1.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version1.effect-fill .desk-menu > ul > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version1.effect-outline .desk-menu > ul > li:hover > a,
.version1.effect-outline .desk-menu > ul > li.one-page-active > a,
.version1.effect-outline .desk-menu > ul > li.current-menu-item > a,
.version1.effect-outline .desk-menu > ul > li.current-menu-parent > a,
.version1.effect-outline .desk-menu > ul > li.current-menu-ancestor > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.one-page-top.version1.effect-outline .desk-menu > ul > li:hover > a,
.one-page-top.version1.effect-outline .desk-menu > ul > li.one-page-active > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version1.effect-overline .desk-menu > ul > li > a:after {
  top: -30px;
}
.version1.effect-underline .desk-menu > ul > li > a:after {
  bottom: -30px;
}
.version1 .menu-item-type-yawp_wim,
.version1 .menu-item-type-yawp_wim a:not(.button) {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version1 .widget_rss a,
.version1 .widget_nz_recent_entries a,
.version1 .widget_recent_comments a,
.version1 .widget_recent_entries a,
.version1 .widget_nz_recent_entries .post .post-date,
.version1 .nz-schedule li,
.version1 .widget_twitter .tweet-time,
.version1 .widget_shopping_cart .cart_list > li > a {
  color: #999999 !important;
}
.version1 .widget_rss a:hover,
.version1 .widget_nz_recent_entries a:hover,
.version1 .widget_recent_comments a:hover,
.version1 .widget_recent_entries a:hover,
.version1 .widget_shopping_cart .cart_list > li > a:hover {
  color: #e2e2e2 !important;
}
.version1 .widget_twitter ul li:before,
.version1 .widget_nz_recent_entries .post-body:before,
.version1 .widget_recent_comments ul li:before {
  color: #999999 !important;
  background-color: #ffb700 !important;
}
.version1 .nz-schedule ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version1 .widget_shopping_cart .cart_list > li,
.version1 .widget_products .product_list_widget > li,
.version1 .widget_recently_viewed_products .product_list_widget > li,
.version1 .widget_recent_reviews .product_list_widget > li,
.version1 .widget_top_rated_products .product_list_widget > li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version1 .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: rgba(153, 153, 153, 0.2) !important;
}
.version1 .widget_calendar td#prev,
.version1 .widget_calendar td#next,
.version1 .widget_calendar td,
.version1 .widget_calendar caption,
.version1 .widget_calendar th:first-child,
.version1 .widget_calendar th:last-child {
  border-color: rgba(153, 153, 153, 0.2);
}
.version1 .widget_tag_cloud .tagcloud a:hover,
.version1 .widget_product_tag_cloud .tagcloud a:hover,
.version1 .post-tags a:hover,
.version1 .projects-tags a:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version1 .widget_calendar td#today {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version1 .widget_tag_cloud .tagcloud a:hover,
.version1 .widget_product_tag_cloud .tagcloud a:hover,
.version1 .post-tags a:hover,
.version1 .projects-tags a:hover,
.version1 .widget_tag_cloud .tagcloud a:hover,
.version1 .widget_product_tag_cloud .tagcloud a:hover,
.version1 .post-tags a:hover,
.version1 .projects-tags a:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version1 .widget_nav_menu ul li:hover a {
  color: #999999 !important;
}
.version1 .widget_product_categories ul li a:hover,
.version1 .widget_shopping_cart p.buttons > a:hover,
.version1 .widget_recent_entries a:hover,
.version1 .widget_categories ul li a:hover,
.version1 .widget_pages ul li a:hover,
.version1 .widget_archive ul li a:hover,
.version1 .widget_meta ul li a:hover,
.version1 .widget_nz_recent_entries .post-title:hover,
.version1 .widget_price_filter .price_slider_amount .button:hover,
.version1 .widget_shopping_cart .cart_list > li > a:hover,
.version1 .widget_products .product_list_widget > li > a:hover,
.version1 .widget_recently_viewed_products .product_list_widget > li > a:hover,
.version1 .widget_recent_reviews .product_list_widget > li > a:hover,
.version1 .widget_top_rated_products .product_list_widget > li > a:hover,
.version1 .widget_recent_entries a:hover,
.version1 .widget_nz_recent_entries .post-title:hover,
.version1 .widget_product_categories ul li a:hover,
.version1 .widget_nav_menu ul li a:hover,
.version1 .widget_recent_comments a:hover {
  color: #e2e2e2 !important;
}
.version1 .menu-item-type-yawp_wim a:hover {
  color: #e2e2e2;
}
.version1 textarea,
.version1 select,
.version1 input[type="date"],
.version1 input[type="datetime"],
.version1 input[type="datetime-local"],
.version1 input[type="email"],
.version1 input[type="month"],
.version1 input[type="number"],
.version1 input[type="password"],
.version1 input[type="search"],
.version1 input[type="tel"],
.version1 input[type="text"],
.version1 input[type="time"],
.version1 input[type="url"],
.version1 input[type="week"] {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.25);
  color: #999999;
}
.version1 textarea:hover,
.version1 select:hover,
.version1 input[type="date"]:hover,
.version1 input[type="datetime"]:hover,
.version1 input[type="datetime-local"]:hover,
.version1 input[type="email"]:hover,
.version1 input[type="month"]:hover,
.version1 input[type="number"]:hover,
.version1 input[type="password"]:hover,
.version1 input[type="search"]:hover,
.version1 input[type="tel"]:hover,
.version1 input[type="text"]:hover,
.version1 input[type="time"]:hover,
.version1 input[type="url"]:hover,
.version1 input[type="week"]:hover {
  color: #e2e2e2 !important;
}
.version1 .desk-menu .button,
.version1 .desk-menu button,
.version1 .desk-menu input[type="reset"],
.version1 .desk-menu input[type="submit"],
.version1 .desk-menu input[type="button"] {
  background-color: #ffb700 !important;
  color: #ffffff !important;
}
.version1 .desk-menu .button:hover,
.version1 .desk-menu button:hover,
.version1 .desk-menu input[type="reset"]:hover,
.version1 .desk-menu input[type="submit"]:hover,
.version1 .desk-menu input[type="button"]:hover {
  background-color: rgb(235, 163, -20) !important;
}
.version1 ::-webkit-input-placeholder {
  color: #999999;
}
.version1 :-moz-placeholder {
  color: #999999;
}
.version1 ::-moz-placeholder {
  color: #999999;
}
.version1 :-ms-input-placeholder {
  color: #999999;
}
.version1 .widget_price_filter .ui-slider .ui-slider-handle {
  border-color: rgba(255, 183, 0, 1) !important;
}
.version1 .widget_shopping_cart p.buttons > a:hover,
.version1 .widget_shopping_cart p.buttons > a:hover,
.version1 .widget_price_filter .price_slider_amount .button:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version1 .widget_price_filter .ui-slider .ui-slider-range {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version1 .widget_shopping_cart p.buttons > a:hover,
.version1 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version1 .yawp_wim_title {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version1 .widget_tag_cloud .tagcloud a,
.version1 .post-tags a,
.version1 .widget_product_tag_cloud .tagcloud a,
.version1 .projects-tags a {
  font-family: Arial, Helvetica, sans-serif;
  color: #999999;
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.3) !important;
}
.version1 .widget_shopping_cart {
  color: #999999;
}
.version1 .widget_shopping_cart .cart_list li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.version1 .widget_shopping_cart .cart_list li .remove {
  background-color: rgb(31, 31, 31) !important;
}
.version1 .widget_shopping_cart .cart_list li .remove:hover {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version1 .widget_shopping_cart .cart_list li img {
  background-color: rgba(153, 153, 153, 0.1);
}
.version1 .widget_shopping_cart .cart_list li:hover img {
  background-color: rgba(153, 153, 153, 0.2);
}
.version1 .widget_shopping_cart p.buttons > a,
.version1 .widget_price_filter .price_slider_amount .button {
  color: #999999 !important;
  box-shadow: inset 0 0 0 2px rgba(153, 153, 153, 0.3) !important;
}
.version1 .widget_shopping_cart p.buttons > a:hover,
.version1 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version1 .star-rating:before {
  color: rgba(153, 153, 153, 0.4) !important;
}
.version1 .widget_nav_menu li a:before,
.version1 .widget_product_categories li a:before,
.version1 .widget_categories ul li a:before,
.version1 .widget_pages ul li a:before,
.version1 .widget_archive ul li a:before,
.version1 .widget_meta ul li a:before {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version1 .yawp_wim_title a {
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.version1 .widget_categories ul li,
.version1 .widget_pages ul li,
.version1 .widget_archive ul li,
.version1 .widget_meta ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.25);
}
.version1.fixed-true.active .header-body,
.version1.fixed-true.active {
  height: 90px;
}
.version1.fixed-true.active.top-true {
  height: 130px;
}
.version1.fixed-true.active .logo,
.version1.fixed-true.active .logo-title {
  height: 90px;
  line-height: 90px;
}
.version1.fixed-true.active .desk-menu .sub-menu {
  top: 90px;
}
.version1.fixed-true.active .search {
  top: 65px;
}
.version1.fixed-true.active .desk-cart-wrap {
  height: 90px;
}
.version1.fixed-true.active .search-toggle-wrap,
.version1.fixed-true.active .desk-cart-toggle,
.version1.fixed-true.active .site-sidebar-toggle {
  margin-top: 25px;
}
.version1.fixed-true.active .woo-cart {
  top: 90px;
}
.version1.fixed-true.active .desk-menu > ul > li {
  height: 90px;
  line-height: 90px;
}
.version1.fixed-true.active .desk-menu > ul > li > a {
  margin-top: 30px;
}
.version1.fixed-true.active .logo-title {
  color: #999999;
}
.version1.fixed-true.active .header-body {
  background-color: rgba(255, 255, 255, 1);
}
.version1.fixed-true.active .desk-menu > ul > li > a {
  color: #999999;
}
.version1.fixed-true.active .desk-cart-toggle span {
  color: #999999;
}
.version1.fixed-true.active .desk-menu > ul > li:hover > a,
.version1.fixed-true.active .desk-menu > ul > li.one-page-active > a,
.version1.fixed-true.active .desk-menu > ul > li.current-menu-item > a,
.version1.fixed-true.active .desk-menu > ul > li.current-menu-parent > a,
.version1.fixed-true.active .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version1.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.one-page-top.version1.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.one-page-top.version1.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  color: #999999;
}
.one-page-top.version1.fixed-true.active .desk-menu > ul > li:hover > a,
.one-page-top.version1.fixed-true.active
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  color: #999999 !important;
}
.version1.fixed-true.active .site-sidebar-toggle {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent;
}
.version1.fixed-true.active .search-true.cart-false .search-toggle:after,
.version1.fixed-true.active .cart-true .desk-cart-wrap:after {
  background-color: rgba(153, 153, 153, 0.2);
}
.version1.fixed-true.active.effect-outline .desk-menu > ul > li:hover > a,
.version1.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a,
.version1.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.version1.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.version1.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.version1.fixed-true.active.effect-underline .desk-menu > ul > li > a:after {
  bottom: -30px;
}
.one-page-top.version1.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li:hover
  > a,
.one-page-top.version1.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version1.fixed-true.active.effect-underline .desk-menu > ul > li > a:after,
.version1.fixed-true.active.effect-overline .desk-menu > ul > li > a:after,
.version1.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.version1.fixed-true.active.effect-fill .desk-menu > ul > li.one-page-active,
.version1.fixed-true.active.effect-fill .desk-menu > ul > li.current-menu-item,
.version1.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-parent,
.version1.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version1.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version1.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version2 .header-top {
  background-color: rgba(250, 250, 250, 1);
}
.version2 .header-top .header-top-menu ul li a,
.version2 .header-top .social-text {
  color: #999999;
  font-weight: normal;
  font-size: 14px;
  text-transform: none;
}
.version2 .header-top .header-top-menu ul li:hover > a {
  color: #ffb700;
}
.version2 .header-top .header-top-menu > ul > li:not(:last-child):after {
  color: rgba(153, 153, 153, 0.3);
}
.version2 .header-top .top-button {
  color: #ffb700;
  background-color: #ffffff;
}
.version2 .header-top .top-button:hover {
  background-color: rgb(235, 235, 235);
}
.version2 .header-top .header-top-social-links a {
  color: #999999 !important;
}
.version2 .header-top .header-top-menu ul li ul.submenu-languages,
.version2 .desk-menu > ul > li ul.submenu-languages {
  width: 149px;
}
.version2.top-false {
  height: 90px;
}
.version2.top-true {
  height: 130px;
}
.version2 .header-body {
  background-color: rgba(255, 255, 255, 1);
  height: 90px;
}
.version2 .logo,
.version2 .logo-title,
.version2.desk .logo-part {
  height: 90px;
  line-height: 90px;
}
.version2 .desk-menu .sub-menu {
  top: 90px;
}
.version2 .search {
  top: 65px;
}
.version2 .desk-cart-wrap {
  height: 90px;
}
.version2 .search-toggle-wrap,
.version2 .desk-cart-toggle,
.version2 .site-sidebar-toggle {
  margin-top: 25px;
}
.version2 .site-sidebar-toggle {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent;
}
.version2 .woo-cart {
  top: 90px;
}
.version2 .desk-menu > ul > li {
  margin-left: 20px !important;
  height: 90px;
  line-height: 90px;
}
.version2:not(.active) .logo-title {
  color: #999999;
}
.version2 .desk-menu > ul > li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 30px;
}
.version2 .desk-cart-toggle span {
  color: #999999;
  margin-left: -11px;
}
.version2 .desk-menu > ul > li.menu-item-language > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.5);
}
.version2 .desk-menu > ul > li.menu-item-language:hover > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.7);
}
.version2 .desk-menu > ul > li:hover > a,
.version2 .desk-menu > ul > li.one-page-active > a,
.version2 .desk-menu > ul > li.current-menu-item > a,
.version2 .desk-menu > ul > li.current-menu-parent > a,
.version2 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.one-page-top.version2 .desk-menu > ul > li:hover > a,
.one-page-top.version2 .desk-menu > ul > li.one-page-active > a {
  color: #999999 !important;
}
.one-page-top.version2 .desk-menu > ul > li.current-menu-item > a,
.one-page-top.version2 .desk-menu > ul > li.current-menu-parent > a,
.one-page-top.version2 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.version2 .desk-menu .sub-menu,
.version2 .header-top .header-top-menu ul li ul,
.version2 .woo-cart {
  background-color: #292929;
}
.version2 .search {
  background-color: #292929 !important;
}
.version2 .desk-menu .sub-menu .sub-menu {
  background-color: rgb(21, 21, 21);
}
.version2 .desk-menu .sub-menu li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version2 .header-top .header-top-menu ul li ul li a {
  color: #999999;
}
.version2 .desk-menu .sub-menu li:hover > a,
.version2 .header-top .header-top-menu ul li ul li:hover > a {
  color: #e2e2e2;
}
.version2 .desk-menu [data-mm="true"] > .sub-menu > li > a {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version2
  .desk-menu
  > ul
  > [data-mm="true"]
  > .sub-menu
  > li:not(:last-child):after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.version2 .menu-item-type-yawp_wim,
.version2 .menu-item-type-yawp_wim a:not(.button) {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version2 .widget_rss a,
.version2 .widget_nz_recent_entries a,
.version2 .widget_recent_comments a,
.version2 .widget_recent_entries a,
.version2 .widget_nz_recent_entries .post .post-date,
.version2 .nz-schedule li,
.version2 .widget_twitter .tweet-time,
.version2 .widget_shopping_cart .cart_list > li > a {
  color: #999999 !important;
}
.version2 .widget_rss a:hover,
.version2 .widget_nz_recent_entries a:hover,
.version2 .widget_recent_comments a:hover,
.version2 .widget_recent_entries a:hover,
.version2 .widget_shopping_cart .cart_list > li > a:hover {
  color: #e2e2e2 !important;
}
.version2 .widget_twitter ul li:before,
.version2 .widget_nz_recent_entries .post-body:before,
.version2 .widget_recent_comments ul li:before {
  color: #999999 !important;
  background-color: #ffb700 !important;
}
.version2 .nz-schedule ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version2 .widget_shopping_cart .cart_list > li,
.version2 .widget_products .product_list_widget > li,
.version2 .widget_recently_viewed_products .product_list_widget > li,
.version2 .widget_recent_reviews .product_list_widget > li,
.version2 .widget_top_rated_products .product_list_widget > li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version2 .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: rgba(153, 153, 153, 0.2) !important;
}
.version2 .widget_calendar td#prev,
.version2 .widget_calendar td#next,
.version2 .widget_calendar td,
.version2 .widget_calendar caption,
.version2 .widget_calendar th:first-child,
.version2 .widget_calendar th:last-child {
  border-color: rgba(153, 153, 153, 0.2);
}
.version2 .widget_tag_cloud .tagcloud a:hover,
.version2 .widget_product_tag_cloud .tagcloud a:hover,
.version2 .post-tags a:hover,
.version2 .projects-tags a:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version2 .widget_calendar td#today {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version2 .widget_tag_cloud .tagcloud a:hover,
.version2 .widget_product_tag_cloud .tagcloud a:hover,
.version2 .post-tags a:hover,
.version2 .projects-tags a:hover,
.version2 .widget_tag_cloud .tagcloud a:hover,
.version2 .widget_product_tag_cloud .tagcloud a:hover,
.version2 .post-tags a:hover,
.version2 .projects-tags a:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version2 .widget_nav_menu ul li:hover a {
  color: #999999 !important;
}
.version2 .widget_product_categories ul li a:hover,
.version2 .widget_shopping_cart p.buttons > a:hover,
.version2 .widget_recent_entries a:hover,
.version2 .widget_categories ul li a:hover,
.version2 .widget_pages ul li a:hover,
.version2 .widget_archive ul li a:hover,
.version2 .widget_meta ul li a:hover,
.version2 .widget_nz_recent_entries .post-title:hover,
.version2 .widget_price_filter .price_slider_amount .button:hover,
.version2 .widget_shopping_cart .cart_list > li > a:hover,
.version2 .widget_products .product_list_widget > li > a:hover,
.version2 .widget_recently_viewed_products .product_list_widget > li > a:hover,
.version2 .widget_recent_reviews .product_list_widget > li > a:hover,
.version2 .widget_top_rated_products .product_list_widget > li > a:hover,
.version2 .widget_recent_entries a:hover,
.version2 .widget_nz_recent_entries .post-title:hover,
.version2 .widget_product_categories ul li a:hover,
.version2 .widget_nav_menu ul li a:hover,
.version2 .widget_recent_comments a:hover {
  color: #e2e2e2 !important;
}
.version2 .menu-item-type-yawp_wim a:hover {
  color: #e2e2e2;
}
.version2 textarea,
.version2 select,
.version2 input[type="date"],
.version2 input[type="datetime"],
.version2 input[type="datetime-local"],
.version2 input[type="email"],
.version2 input[type="month"],
.version2 input[type="number"],
.version2 input[type="password"],
.version2 input[type="search"],
.version2 input[type="tel"],
.version2 input[type="text"],
.version2 input[type="time"],
.version2 input[type="url"],
.version2 input[type="week"] {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.25);
  color: #999999;
}
.version2 textarea:hover,
.version2 select:hover,
.version2 input[type="date"]:hover,
.version2 input[type="datetime"]:hover,
.version2 input[type="datetime-local"]:hover,
.version2 input[type="email"]:hover,
.version2 input[type="month"]:hover,
.version2 input[type="number"]:hover,
.version2 input[type="password"]:hover,
.version2 input[type="search"]:hover,
.version2 input[type="tel"]:hover,
.version2 input[type="text"]:hover,
.version2 input[type="time"]:hover,
.version2 input[type="url"]:hover,
.version2 input[type="week"]:hover {
  color: #e2e2e2 !important;
}
.version2 .desk-menu .button,
.version2 .desk-menu button,
.version2 .desk-menu input[type="reset"],
.version2 .desk-menu input[type="submit"],
.version2 .desk-menu input[type="button"] {
  background-color: #ffb700 !important;
  color: #ffffff !important;
}
.version2 .desk-menu .button:hover,
.version2 .desk-menu button:hover,
.version2 .desk-menu input[type="reset"]:hover,
.version2 .desk-menu input[type="submit"]:hover,
.version2 .desk-menu input[type="button"]:hover {
  background-color: rgb(235, 163, -20) !important;
}
.version2 ::-webkit-input-placeholder {
  color: #999999;
}
.version2 :-moz-placeholder {
  color: #999999;
}
.version2 ::-moz-placeholder {
  color: #999999;
}
.version2 :-ms-input-placeholder {
  color: #999999;
}
.version2 .widget_price_filter .ui-slider .ui-slider-handle {
  border-color: rgba(255, 183, 0, 1) !important;
}
.version2 .widget_shopping_cart p.buttons > a:hover,
.version2 .widget_shopping_cart p.buttons > a:hover,
.version2 .widget_price_filter .price_slider_amount .button:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version2 .widget_price_filter .ui-slider .ui-slider-range {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version2 .widget_shopping_cart p.buttons > a:hover,
.version2 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version2 .yawp_wim_title {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version2 .widget_tag_cloud .tagcloud a,
.version2 .post-tags a,
.version2 .widget_product_tag_cloud .tagcloud a,
.version2 .projects-tags a {
  font-family: Arial, Helvetica, sans-serif;
  color: #999999;
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.3) !important;
}
.version2 .widget_shopping_cart {
  color: #999999;
}
.version2 .widget_shopping_cart .cart_list li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.version2 .widget_shopping_cart .cart_list li .remove {
  background-color: rgb(31, 31, 31) !important;
}
.version2 .widget_shopping_cart .cart_list li .remove:hover {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version2 .widget_shopping_cart .cart_list li img {
  background-color: rgba(153, 153, 153, 0.1);
}
.version2 .widget_shopping_cart .cart_list li:hover img {
  background-color: rgba(153, 153, 153, 0.2);
}
.version2 .widget_shopping_cart p.buttons > a,
.version2 .widget_price_filter .price_slider_amount .button {
  color: #999999 !important;
  box-shadow: inset 0 0 0 2px rgba(153, 153, 153, 0.3) !important;
}
.version2 .widget_shopping_cart p.buttons > a:hover,
.version2 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version2 .star-rating:before {
  color: rgba(153, 153, 153, 0.4) !important;
}
.version2 .widget_nav_menu li a:before,
.version2 .widget_product_categories li a:before,
.version2 .widget_categories ul li a:before,
.version2 .widget_pages ul li a:before,
.version2 .widget_archive ul li a:before,
.version2 .widget_meta ul li a:before {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version2 .yawp_wim_title a {
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.version2 .widget_categories ul li,
.version2 .widget_pages ul li,
.version2 .widget_archive ul li,
.version2 .widget_meta ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.25);
}
.version2 .desk-menu .sub-menu .label {
  font-family: Arial, Helvetica, sans-serif;
}
.version2 .search-true.cart-false .search-toggle:after,
.version2 .cart-true .desk-cart-wrap:after {
  background-color: rgba(153, 153, 153, 0.2);
}
.version2 .search input[type="text"],
.version2 .woo-cart {
  color: #999999;
}
.version2 .woo-cart .widget_shopping_cart .cart_list li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.version2 .woo-cart .widget_shopping_cart .cart_list li .remove {
  background-color: rgb(21, 21, 21);
}
.version2 .woo-cart .widget_shopping_cart .cart_list li .remove:hover {
  background-color: rgb(11, 11, 11) !important;
}
.version2 .woo-cart .widget_shopping_cart .cart_list li img {
  background-color: rgba(153, 153, 153, 0.1);
}
.version2 .woo-cart .widget_shopping_cart .cart_list li:hover img {
  background-color: rgba(153, 153, 153, 0.2);
}
.version2.effect-underline .desk-menu > ul > li > a:after,
.version2.effect-overline .desk-menu > ul > li > a:after,
.version2.effect-fill .desk-menu > ul > li:hover,
.version2.effect-fill .desk-menu > ul > li.one-page-active > a,
.version2.effect-fill .desk-menu > ul > li.current-menu-item,
.version2.effect-fill .desk-menu > ul > li.current-menu-parent,
.version2.effect-fill .desk-menu > ul > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version2.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version2.effect-fill .desk-menu > ul > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version2.effect-outline .desk-menu > ul > li:hover > a,
.version2.effect-outline .desk-menu > ul > li.one-page-active > a,
.version2.effect-outline .desk-menu > ul > li.current-menu-item > a,
.version2.effect-outline .desk-menu > ul > li.current-menu-parent > a,
.version2.effect-outline .desk-menu > ul > li.current-menu-ancestor > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.one-page-top.version2.effect-outline .desk-menu > ul > li:hover > a,
.one-page-top.version2.effect-outline .desk-menu > ul > li.one-page-active > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version2.effect-overline .desk-menu > ul > li > a:after {
  top: -30px;
}
.version2.effect-underline .desk-menu > ul > li > a:after {
  bottom: -30px;
}
.version2.fixed-true.active .header-body,
.version2.fixed-true.active {
  height: 90px;
}
.version2.fixed-true.active.top-true {
  height: 130px;
}
.version2.fixed-true.active .logo,
.version2.fixed-true.active .logo-title {
  height: 90px;
  line-height: 90px;
}
.version2.fixed-true.active .desk-menu .sub-menu {
  top: 90px;
}
.version2.fixed-true.active .search {
  top: 65px;
}
.version2.fixed-true.active .desk-cart-wrap {
  height: 90px;
}
.version2.fixed-true.active .search-toggle-wrap,
.version2.fixed-true.active .desk-cart-toggle,
.version2.fixed-true.active .site-sidebar-toggle {
  margin-top: 25px;
}
.version2.fixed-true.active .woo-cart {
  top: 90px;
}
.version2.fixed-true.active .desk-menu > ul > li {
  height: 90px;
  line-height: 90px;
}
.version2.fixed-true.active .desk-menu > ul > li > a {
  margin-top: 30px;
}
.version2.fixed-true.active .logo-title {
  color: #999999;
}
.version2.fixed-true.active .header-body {
  background-color: rgba(255, 255, 255, 1);
}
.version2.fixed-true.active .desk-menu > ul > li > a {
  color: #999999;
}
.version2.fixed-true.active .desk-cart-toggle span {
  color: #999999;
}
.version2.fixed-true.active .desk-menu > ul > li:hover > a,
.version2.fixed-true.active .desk-menu > ul > li.one-page-active > a,
.version2.fixed-true.active .desk-menu > ul > li.current-menu-item > a,
.version2.fixed-true.active .desk-menu > ul > li.current-menu-parent > a,
.version2.fixed-true.active .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version2.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.one-page-top.version2.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.one-page-top.version2.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  color: #999999;
}
.one-page-top.version2.fixed-true.active .desk-menu > ul > li:hover > a,
.one-page-top.version2.fixed-true.active
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  color: #999999 !important;
}
.version2.fixed-true.active .site-sidebar-toggle {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent;
}
.version2.fixed-true.active .search-true.cart-false .search-toggle:after,
.version2.fixed-true.active .cart-true .desk-cart-wrap:after {
  background-color: rgba(153, 153, 153, 0.2);
}
.version2.fixed-true.active.effect-outline .desk-menu > ul > li:hover > a,
.version2.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a,
.version2.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.version2.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.version2.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.version2.fixed-true.active.effect-underline .desk-menu > ul > li > a:after {
  bottom: -30px;
}
.one-page-top.version2.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li:hover
  > a,
.one-page-top.version2.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version2.fixed-true.active.effect-underline .desk-menu > ul > li > a:after,
.version2.fixed-true.active.effect-overline .desk-menu > ul > li > a:after,
.version2.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.version2.fixed-true.active.effect-fill .desk-menu > ul > li.one-page-active,
.version2.fixed-true.active.effect-fill .desk-menu > ul > li.current-menu-item,
.version2.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-parent,
.version2.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version2.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version2.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version2.fixed-true.active.effect-overline .desk-menu > ul > li > a:after {
  top: -30px;
}
.version3 .header-body {
  background-color: rgba(255, 255, 255, 1);
  height: 90px;
}
.version3 .logo,
.version3 .logo-title {
  height: 90px;
  line-height: 90px;
}
.version3 .desk-menu .sub-menu {
  top: 90px;
}
.version3 .search {
  top: 65px;
}
.version3 .desk-cart-wrap {
  height: 90px;
}
.version3 .search-toggle-wrap,
.version3 .desk-cart-toggle,
.version3 .site-sidebar-toggle,
.version3 .header-social-links {
  margin-top: 25px;
}
.version3 .site-sidebar-toggle {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent;
}
.version3 .woo-cart {
  top: 90px;
}
.version3 .desk-menu > ul > li {
  margin-left: 20px !important;
  height: 90px;
  line-height: 90px;
}
.version3:not(.active) .logo-title {
  color: #999999;
}
.version3 .header-social-links a {
  color: #999999 !important;
}
.version3 .desk-menu > ul > li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 30px;
}
.version3 .desk-cart-toggle span {
  color: #999999;
  margin-left: -11px;
}
.version3 .desk-menu > ul > li.menu-item-language > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.5);
}
.version3 .desk-menu > ul > li.menu-item-language:hover > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.7);
}
.version3 .desk-menu > ul > li:hover > a,
.version3 .desk-menu > ul > li.one-page-active > a,
.version3 .desk-menu > ul > li.current-menu-item > a,
.version3 .desk-menu > ul > li.current-menu-parent > a,
.version3 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version3 .desk-menu > ul > li:hover > a,
.one-page-top.version3 .desk-menu > ul > li.one-page-active > a {
  color: #999999 !important;
}
.one-page-top.version3 .desk-menu > ul > li.current-menu-item > a,
.one-page-top.version3 .desk-menu > ul > li.current-menu-parent > a,
.one-page-top.version3 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.version3 .desk-menu .sub-menu,
.version3 .header-top .header-top-menu ul li ul,
.version3 .woo-cart {
  background-color: #292929;
}
.version3 .search {
  background-color: #292929 !important;
}
.version3 .desk-menu .sub-menu .sub-menu {
  background-color: rgb(21, 21, 21);
}
.version3 .desk-menu .sub-menu li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version3 .desk-menu .sub-menu li:hover > a {
  color: #e2e2e2;
}
.version3 .desk-menu [data-mm="true"] > .sub-menu > li > a {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version3
  .desk-menu
  > ul
  > [data-mm="true"]
  > .sub-menu
  > li:not(:last-child):after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.version3 .menu-item-type-yawp_wim,
.version3 .menu-item-type-yawp_wim a:not(.button) {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version3 .widget_rss a,
.version3 .widget_nz_recent_entries a,
.version3 .widget_recent_comments a,
.version3 .widget_recent_entries a,
.version3 .widget_nz_recent_entries .post .post-date,
.version3 .nz-schedule li,
.version3 .widget_twitter .tweet-time,
.version3 .widget_shopping_cart .cart_list > li > a {
  color: #999999 !important;
}
.version3 .widget_rss a:hover,
.version3 .widget_nz_recent_entries a:hover,
.version3 .widget_recent_comments a:hover,
.version3 .widget_recent_entries a:hover,
.version3 .widget_shopping_cart .cart_list > li > a:hover {
  color: #e2e2e2 !important;
}
.version3 .widget_twitter ul li:before,
.version3 .widget_nz_recent_entries .post-body:before,
.version3 .widget_recent_comments ul li:before {
  color: #999999 !important;
  background-color: #ffb700 !important;
}
.version3 .nz-schedule ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version3 .widget_shopping_cart .cart_list > li,
.version3 .widget_products .product_list_widget > li,
.version3 .widget_recently_viewed_products .product_list_widget > li,
.version3 .widget_recent_reviews .product_list_widget > li,
.version3 .widget_top_rated_products .product_list_widget > li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version3 .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: rgba(153, 153, 153, 0.2) !important;
}
.version3 .widget_calendar td#prev,
.version3 .widget_calendar td#next,
.version3 .widget_calendar td,
.version3 .widget_calendar caption,
.version3 .widget_calendar th:first-child,
.version3 .widget_calendar th:last-child {
  border-color: rgba(153, 153, 153, 0.2);
}
.version3 .widget_tag_cloud .tagcloud a:hover,
.version3 .widget_product_tag_cloud .tagcloud a:hover,
.version3 .post-tags a:hover,
.version3 .projects-tags a:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version3 .widget_calendar td#today {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version3 .widget_tag_cloud .tagcloud a:hover,
.version3 .widget_product_tag_cloud .tagcloud a:hover,
.version3 .post-tags a:hover,
.version3 .projects-tags a:hover,
.version3 .widget_tag_cloud .tagcloud a:hover,
.version3 .widget_product_tag_cloud .tagcloud a:hover,
.version3 .post-tags a:hover,
.version3 .projects-tags a:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version3 .widget_nav_menu ul li:hover a {
  color: #999999 !important;
}
.version3 .widget_product_categories ul li a:hover,
.version3 .widget_shopping_cart p.buttons > a:hover,
.version3 .widget_recent_entries a:hover,
.version3 .widget_categories ul li a:hover,
.version3 .widget_pages ul li a:hover,
.version3 .widget_archive ul li a:hover,
.version3 .widget_meta ul li a:hover,
.version3 .widget_nz_recent_entries .post-title:hover,
.version3 .widget_price_filter .price_slider_amount .button:hover,
.version3 .widget_shopping_cart .cart_list > li > a:hover,
.version3 .widget_products .product_list_widget > li > a:hover,
.version3 .widget_recently_viewed_products .product_list_widget > li > a:hover,
.version3 .widget_recent_reviews .product_list_widget > li > a:hover,
.version3 .widget_top_rated_products .product_list_widget > li > a:hover,
.version3 .widget_recent_entries a:hover,
.version3 .widget_nz_recent_entries .post-title:hover,
.version3 .widget_product_categories ul li a:hover,
.version3 .widget_nav_menu ul li a:hover,
.version3 .widget_recent_comments a:hover {
  color: #e2e2e2 !important;
}
.version3 .menu-item-type-yawp_wim a:hover {
  color: #e2e2e2;
}
.version3 textarea,
.version3 select,
.version3 input[type="date"],
.version3 input[type="datetime"],
.version3 input[type="datetime-local"],
.version3 input[type="email"],
.version3 input[type="month"],
.version3 input[type="number"],
.version3 input[type="password"],
.version3 input[type="search"],
.version3 input[type="tel"],
.version3 input[type="text"],
.version3 input[type="time"],
.version3 input[type="url"],
.version3 input[type="week"] {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.25);
  color: #999999;
}
.version3 textarea:hover,
.version3 select:hover,
.version3 input[type="date"]:hover,
.version3 input[type="datetime"]:hover,
.version3 input[type="datetime-local"]:hover,
.version3 input[type="email"]:hover,
.version3 input[type="month"]:hover,
.version3 input[type="number"]:hover,
.version3 input[type="password"]:hover,
.version3 input[type="search"]:hover,
.version3 input[type="tel"]:hover,
.version3 input[type="text"]:hover,
.version3 input[type="time"]:hover,
.version3 input[type="url"]:hover,
.version3 input[type="week"]:hover {
  color: #e2e2e2 !important;
}
.version3 .desk-menu .button,
.version3 .desk-menu button,
.version3 .desk-menu input[type="reset"],
.version3 .desk-menu input[type="submit"],
.version3 .desk-menu input[type="button"] {
  background-color: #ffb700 !important;
  color: #ffffff !important;
}
.version3 .desk-menu .button:hover,
.version3 .desk-menu button:hover,
.version3 .desk-menu input[type="reset"]:hover,
.version3 .desk-menu input[type="submit"]:hover,
.version3 .desk-menu input[type="button"]:hover {
  background-color: rgb(235, 163, -20) !important;
}
.version3 ::-webkit-input-placeholder {
  color: #999999;
}
.version3 :-moz-placeholder {
  color: #999999;
}
.version3 ::-moz-placeholder {
  color: #999999;
}
.version3 :-ms-input-placeholder {
  color: #999999;
}
.version3 .widget_price_filter .ui-slider .ui-slider-handle {
  border-color: rgba(255, 183, 0, 1) !important;
}
.version3 .widget_shopping_cart p.buttons > a:hover,
.version3 .widget_shopping_cart p.buttons > a:hover,
.version3 .widget_price_filter .price_slider_amount .button:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version3 .widget_price_filter .ui-slider .ui-slider-range {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version3 .widget_shopping_cart p.buttons > a:hover,
.version3 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version3 .yawp_wim_title {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version3 .widget_tag_cloud .tagcloud a,
.version3 .post-tags a,
.version3 .widget_product_tag_cloud .tagcloud a,
.version3 .projects-tags a {
  font-family: Arial, Helvetica, sans-serif;
  color: #999999;
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.3) !important;
}
.version3 .widget_shopping_cart {
  color: #999999;
}
.version3 .widget_shopping_cart .cart_list li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.version3 .widget_shopping_cart .cart_list li .remove {
  background-color: rgb(31, 31, 31) !important;
}
.version3 .widget_shopping_cart .cart_list li .remove:hover {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version3 .widget_shopping_cart .cart_list li img {
  background-color: rgba(153, 153, 153, 0.1);
}
.version3 .widget_shopping_cart .cart_list li:hover img {
  background-color: rgba(153, 153, 153, 0.2);
}
.version3 .widget_shopping_cart p.buttons > a,
.version3 .widget_price_filter .price_slider_amount .button {
  color: #999999 !important;
  box-shadow: inset 0 0 0 2px rgba(153, 153, 153, 0.3) !important;
}
.version3 .widget_shopping_cart p.buttons > a:hover,
.version3 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version3 .star-rating:before {
  color: rgba(153, 153, 153, 0.4) !important;
}
.version3 .widget_nav_menu li a:before,
.version3 .widget_product_categories li a:before,
.version3 .widget_categories ul li a:before,
.version3 .widget_pages ul li a:before,
.version3 .widget_archive ul li a:before,
.version3 .widget_meta ul li a:before {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version3 .yawp_wim_title a {
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.version3 .widget_categories ul li,
.version3 .widget_pages ul li,
.version3 .widget_archive ul li,
.version3 .widget_meta ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.25);
}
.version3 .desk-menu .sub-menu .label {
  font-family: Arial, Helvetica, sans-serif;
}
.version3 .search-true.cart-false .search-toggle:after,
.version3 .cart-true .desk-cart-wrap:after {
  background-color: rgba(153, 153, 153, 0.2);
}
.version3 .search input[type="text"],
.version3 .woo-cart {
  color: #999999;
}
.version3 .woo-cart .widget_shopping_cart .cart_list li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.version3 .woo-cart .widget_shopping_cart .cart_list li .remove {
  background-color: rgb(21, 21, 21);
}
.version3 .woo-cart .widget_shopping_cart .cart_list li .remove:hover {
  background-color: rgb(11, 11, 11) !important;
}
.version3 .woo-cart .widget_shopping_cart .cart_list li img {
  background-color: rgba(153, 153, 153, 0.1);
}
.version3 .woo-cart .widget_shopping_cart .cart_list li:hover img {
  background-color: rgba(153, 153, 153, 0.2);
}
.version3.effect-underline .desk-menu > ul > li > a:after,
.version3.effect-overline .desk-menu > ul > li > a:after,
.version3.effect-fill .desk-menu > ul > li:hover,
.version3.effect-fill .desk-menu > ul > li.one-page-active > a,
.version3.effect-fill .desk-menu > ul > li.current-menu-item,
.version3.effect-fill .desk-menu > ul > li.current-menu-parent,
.version3.effect-fill .desk-menu > ul > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version3.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version3.effect-fill .desk-menu > ul > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version3.effect-outline .desk-menu > ul > li:hover > a,
.version3.effect-outline .desk-menu > ul > li.one-page-active > a,
.version3.effect-outline .desk-menu > ul > li.current-menu-item > a,
.version3.effect-outline .desk-menu > ul > li.current-menu-parent > a,
.version3.effect-outline .desk-menu > ul > li.current-menu-ancestor > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.one-page-top.version3.effect-outline .desk-menu > ul > li:hover > a,
.one-page-top.version3.effect-outline .desk-menu > ul > li.one-page-active > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version3.effect-overline .desk-menu > ul > li > a:after {
  top: -30px;
}
.version3.effect-underline .desk-menu > ul > li > a:after {
  bottom: -30px;
}
.version3.fixed-true.active .header-body,
.version3.fixed-true.active {
  height: 90px;
}
.version3.fixed-true.active .logo,
.version3.fixed-true.active .logo-title {
  height: 90px;
  line-height: 90px;
}
.version3.fixed-true.active .search,
.version3.fixed-true.active .desk-menu .sub-menu {
  top: 90px;
}
.version3.fixed-true.active .search {
  top: 65px;
}
.version3.fixed-true.active .desk-cart-wrap {
  height: 90px;
}
.version3.fixed-true.active .header-social-links,
.version3.fixed-true.active .search-toggle-wrap,
.version3.fixed-true.active .desk-cart-toggle,
.version3.fixed-true.active .site-sidebar-toggle {
  margin-top: 25px;
}
.version3.fixed-true.active .woo-cart {
  top: 90px;
}
.version3.fixed-true.active .desk-menu > ul > li {
  height: 90px;
  line-height: 90px;
}
.version3.fixed-true.active .desk-menu > ul > li > a {
  margin-top: 30px;
}
.version3.fixed-true.active .header-social-links a {
  color: #999999;
}
.version3.fixed-true.active .logo-title {
  color: #999999;
}
.version3.fixed-true.active .header-body {
  background-color: rgba(255, 255, 255, 1);
}
.version3.fixed-true.active .desk-menu > ul > li > a {
  color: #999999;
}
.version3.fixed-true.active .desk-cart-toggle span {
  color: #999999;
}
.version3.fixed-true.active .desk-menu > ul > li:hover > a,
.version3.fixed-true.active .desk-menu > ul > li.one-page-active > a,
.version3.fixed-true.active .desk-menu > ul > li.current-menu-item > a,
.version3.fixed-true.active .desk-menu > ul > li.current-menu-parent > a,
.version3.fixed-true.active .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version3.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.one-page-top.version3.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.one-page-top.version3.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  color: #999999;
}
.one-page-top.version3.fixed-true.active .desk-menu > ul > li:hover > a,
.one-page-top.version3.fixed-true.active
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  color: #999999 !important;
}
.version3.fixed-true.active .site-sidebar-toggle {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent;
}
.version3.fixed-true.active .search-true.cart-false .search-toggle:after,
.version3.fixed-true.active .cart-true .desk-cart-wrap:after {
  background-color: rgba(153, 153, 153, 0.2);
}
.version3.fixed-true.active.effect-outline .desk-menu > ul > li:hover > a,
.version3.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a,
.version3.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.version3.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.version3.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.version3.fixed-true.active.effect-underline .desk-menu > ul > li > a:after {
  bottom: -30px;
}
.one-page-top.version3.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li:hover
  > a,
.one-page-top.version3.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version3.fixed-true.active.effect-underline .desk-menu > ul > li > a:after,
.version3.fixed-true.active.effect-overline .desk-menu > ul > li > a:after,
.version3.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.version3.fixed-true.active.effect-fill .desk-menu > ul > li.one-page-active,
.version3.fixed-true.active.effect-fill .desk-menu > ul > li.current-menu-item,
.version3.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-parent,
.version3.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version3.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version3.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
.one-page-bullets a[href*="#"]:hover,
.one-page-bullets .one-page-active[href*="#"] {
  box-shadow: inset 0 0 0 10px #ffb900;
}
.desk-menu > ul > li > a > .txt .label:before {
  border-color: #ffb900 transparent transparent transparent;
}
.version4 .header-top {
  background-color: rgba(202, 162, 105, 1);
}
.version4 .header-top .header-top-menu ul li a,
.version4 .header-top .social-text {
  color: #ffffff;
  font-weight: normal;
  font-size: 14px;
  text-transform: none;
}
.version4 .header-top .header-top-menu ul li:hover > a {
  color: #ffb700;
}
.version4 .header-top .header-top-menu > ul > li:not(:last-child):after {
  color: rgba(255, 255, 255, 0.3);
}
.version4 .header-top .top-button {
  color: #ffffff;
  background-color: #ffb700;
}
.version4 .header-top .top-button:hover {
  background-color: rgb(235, 163, -20);
}
.version4 .header-top .header-top-social-links a {
  color: #1e73be !important;
}
.version4 .header-top .header-top-menu ul li ul.submenu-languages,
.version4 .desk-menu > ul > li ul.submenu-languages {
  width: 149px;
}

.desk.version4 .logo,
.desk.version4 .logo-title,
.desk.version4 .logo:before,
.desk.version4 .logo-title:before {
  background-color: rgba(255, 255, 255, 1);
}
.desk.version4 .logo:after,
.desk.version4 .logo-title:after {
  border-color: transparent transparent transparent rgba(255, 255, 255, 1);
}
.version4 .logo,
.version4 .logo-title {
  height: 110px;
  line-height: 110px;
}
.version4 .desk-menu .sub-menu {
  top: 70px;
}
.version4 .search {
  top: 55px;
}
.version4 .desk-cart-wrap {
  height: 70px;
}
.version4 .search-toggle-wrap,
.version4 .desk-cart-toggle,
.version4 .site-sidebar-toggle {
  margin-top: 15px;
}
.version4 .site-sidebar-toggle {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent;
}
.version4 .woo-cart {
  top: 70px;
}
.version4 .desk-menu > ul > li {
  margin-left: 20px !important;
  height: 70px;
  line-height: 70px;
}
.version4:not(.active) .logo-title {
  color: #999999;
}
.version4 .desk-menu > ul > li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 20px;
}
.version4 .desk-cart-toggle span {
  color: #999999;
  margin-left: -11px;
}
.version4 .desk-menu > ul > li.menu-item-language > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.5);
}
.version4 .desk-menu > ul > li.menu-item-language:hover > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.7);
}
.version4 .desk-menu > ul > li:hover > a,
.version4 .desk-menu > ul > li.one-page-active > a,
.version4 .desk-menu > ul > li.current-menu-item > a,
.version4 .desk-menu > ul > li.current-menu-parent > a,
.version4 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version4 .desk-menu > ul > li.current-menu-item > a,
.one-page-top.version4 .desk-menu > ul > li.current-menu-parent > a,
.one-page-top.version4 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version4 .desk-menu > ul > li:hover > a,
.one-page-top.version4 .desk-menu > ul > li.one-page-active > a {
  color: #999999 !important;
}
.version4 .desk-menu .sub-menu,
.version4 .header-top .header-top-menu ul li ul,
.version4 .woo-cart {
  background-color: #292929;
}
.version4 .search {
  background-color: #292929 !important;
}
.version4 .desk-menu .sub-menu .sub-menu {
  background-color: rgb(21, 21, 21);
}
.version4 .desk-menu .sub-menu li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version4 .header-top .header-top-menu ul li ul li a {
  color: #999999;
}
.version4 .desk-menu .sub-menu li:hover > a,
.version4 .header-top .header-top-menu ul li ul li:hover > a {
  color: #e2e2e2;
}
.version4 .desk-menu [data-mm="true"] > .sub-menu > li > a {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version4
  .desk-menu
  > ul
  > [data-mm="true"]
  > .sub-menu
  > li:not(:last-child):after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.version4 .desk-menu .sub-menu .label {
  font-family: Arial, Helvetica, sans-serif;
}
.version4 .search-true.cart-false .search-toggle:after,
.version4 .cart-true .desk-cart-wrap:after {
  background-color: rgba(153, 153, 153, 0.2);
}
.version4 .search input[type="text"] {
  color: #999999;
}
.version4.effect-underline .desk-menu > ul > li > a:after,
.version4.effect-overline .desk-menu > ul > li > a:after,
.version4.effect-fill .desk-menu > ul > li:hover,
.version4.effect-fill .desk-menu > ul > li.one-page-active > a,
.version4.effect-fill .desk-menu > ul > li.current-menu-item,
.version4.effect-fill .desk-menu > ul > li.current-menu-parent,
.version4.effect-fill .desk-menu > ul > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version4.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version4.effect-fill .desk-menu > ul > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version4.effect-outline .desk-menu > ul > li:hover > a,
.version4.effect-outline .desk-menu > ul > li.one-page-active > a,
.version4.effect-outline .desk-menu > ul > li.current-menu-item > a,
.version4.effect-outline .desk-menu > ul > li.current-menu-parent > a,
.version4.effect-outline .desk-menu > ul > li.current-menu-ancestor > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.one-page-top.version4.effect-outline .desk-menu > ul > li:hover > a,
.one-page-top.version4.effect-outline .desk-menu > ul > li.one-page-active > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version4.effect-overline .desk-menu > ul > li > a:after {
  top: -20px;
}
.version4.effect-underline .desk-menu > ul > li > a:after {
  bottom: -20px;
}
.version4 .menu-item-type-yawp_wim,
.version4 .menu-item-type-yawp_wim a:not(.button) {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version4 .widget_rss a,
.version4 .widget_nz_recent_entries a,
.version4 .widget_recent_comments a,
.version4 .widget_recent_entries a,
.version4 .widget_nz_recent_entries .post .post-date,
.version4 .nz-schedule li,
.version4 .widget_twitter .tweet-time,
.version4 .widget_shopping_cart .cart_list > li > a {
  color: #999999 !important;
}
.version4 .widget_rss a:hover,
.version4 .widget_nz_recent_entries a:hover,
.version4 .widget_recent_comments a:hover,
.version4 .widget_recent_entries a:hover,
.version4 .widget_shopping_cart .cart_list > li > a:hover {
  color: #e2e2e2 !important;
}
.version4 .widget_twitter ul li:before,
.version4 .widget_nz_recent_entries .post-body:before,
.version4 .widget_recent_comments ul li:before {
  color: #999999 !important;
  background-color: #ffb700 !important;
}
.version4 .nz-schedule ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version4 .widget_shopping_cart .cart_list > li,
.version4 .widget_products .product_list_widget > li,
.version4 .widget_recently_viewed_products .product_list_widget > li,
.version4 .widget_recent_reviews .product_list_widget > li,
.version4 .widget_top_rated_products .product_list_widget > li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version4 .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: rgba(153, 153, 153, 0.2) !important;
}
.version4 .widget_calendar td#prev,
.version4 .widget_calendar td#next,
.version4 .widget_calendar td,
.version4 .widget_calendar caption,
.version4 .widget_calendar th:first-child,
.version4 .widget_calendar th:last-child {
  border-color: rgba(153, 153, 153, 0.2);
}
.version4 .widget_tag_cloud .tagcloud a:hover,
.version4 .widget_product_tag_cloud .tagcloud a:hover,
.version4 .post-tags a:hover,
.version4 .projects-tags a:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version4 .widget_calendar td#today {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version4 .widget_tag_cloud .tagcloud a:hover,
.version4 .widget_product_tag_cloud .tagcloud a:hover,
.version4 .post-tags a:hover,
.version4 .projects-tags a:hover,
.version4 .widget_tag_cloud .tagcloud a:hover,
.version4 .widget_product_tag_cloud .tagcloud a:hover,
.version4 .post-tags a:hover,
.version4 .projects-tags a:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version4 .widget_nav_menu ul li:hover a {
  color: #999999 !important;
}
.version4 .widget_product_categories ul li a:hover,
.version4 .widget_shopping_cart p.buttons > a:hover,
.version4 .widget_recent_entries a:hover,
.version4 .widget_categories ul li a:hover,
.version4 .widget_pages ul li a:hover,
.version4 .widget_archive ul li a:hover,
.version4 .widget_meta ul li a:hover,
.version4 .widget_nz_recent_entries .post-title:hover,
.version4 .widget_price_filter .price_slider_amount .button:hover,
.version4 .widget_shopping_cart .cart_list > li > a:hover,
.version4 .widget_products .product_list_widget > li > a:hover,
.version4 .widget_recently_viewed_products .product_list_widget > li > a:hover,
.version4 .widget_recent_reviews .product_list_widget > li > a:hover,
.version4 .widget_top_rated_products .product_list_widget > li > a:hover,
.version4 .widget_recent_entries a:hover,
.version4 .widget_nz_recent_entries .post-title:hover,
.version4 .widget_product_categories ul li a:hover,
.version4 .widget_nav_menu ul li a:hover,
.version4 .widget_recent_comments a:hover {
  color: #e2e2e2 !important;
}
.version4 .menu-item-type-yawp_wim a:hover {
  color: #e2e2e2;
}
.version4 textarea,
.version4 select,
.version4 input[type="date"],
.version4 input[type="datetime"],
.version4 input[type="datetime-local"],
.version4 input[type="email"],
.version4 input[type="month"],
.version4 input[type="number"],
.version4 input[type="password"],
.version4 input[type="search"],
.version4 input[type="tel"],
.version4 input[type="text"],
.version4 input[type="time"],
.version4 input[type="url"],
.version4 input[type="week"] {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.25);
  color: #999999;
}
.version4 textarea:hover,
.version4 select:hover,
.version4 input[type="date"]:hover,
.version4 input[type="datetime"]:hover,
.version4 input[type="datetime-local"]:hover,
.version4 input[type="email"]:hover,
.version4 input[type="month"]:hover,
.version4 input[type="number"]:hover,
.version4 input[type="password"]:hover,
.version4 input[type="search"]:hover,
.version4 input[type="tel"]:hover,
.version4 input[type="text"]:hover,
.version4 input[type="time"]:hover,
.version4 input[type="url"]:hover,
.version4 input[type="week"]:hover {
  color: #e2e2e2 !important;
}
.version4 .desk-menu .button,
.version4 .desk-menu button,
.version4 .desk-menu input[type="reset"],
.version4 .desk-menu input[type="submit"],
.version4 .desk-menu input[type="button"] {
  background-color: #ffb700 !important;
  color: #ffffff !important;
}
.version4 .desk-menu .button:hover,
.version4 .desk-menu button:hover,
.version4 .desk-menu input[type="reset"]:hover,
.version4 .desk-menu input[type="submit"]:hover,
.version4 .desk-menu input[type="button"]:hover {
  background-color: rgb(235, 163, -20) !important;
}
.version4 ::-webkit-input-placeholder {
  color: #999999;
}
.version4 :-moz-placeholder {
  color: #999999;
}
.version4 ::-moz-placeholder {
  color: #999999;
}
.version4 :-ms-input-placeholder {
  color: #999999;
}
.version4 .widget_price_filter .ui-slider .ui-slider-handle {
  border-color: rgba(255, 183, 0, 1) !important;
}
.version4 .widget_shopping_cart p.buttons > a:hover,
.version4 .widget_shopping_cart p.buttons > a:hover,
.version4 .widget_price_filter .price_slider_amount .button:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version4 .widget_price_filter .ui-slider .ui-slider-range {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version4 .widget_shopping_cart p.buttons > a:hover,
.version4 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version4 .yawp_wim_title {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version4 .widget_tag_cloud .tagcloud a,
.version4 .post-tags a,
.version4 .widget_product_tag_cloud .tagcloud a,
.version4 .projects-tags a {
  font-family: Arial, Helvetica, sans-serif;
  color: #999999;
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.3) !important;
}
.version4 .widget_shopping_cart {
  color: #999999;
}
.version4 .widget_shopping_cart .cart_list li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.version4 .widget_shopping_cart .cart_list li .remove {
  background-color: rgb(31, 31, 31) !important;
}
.version4 .widget_shopping_cart .cart_list li .remove:hover {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version4 .widget_shopping_cart .cart_list li img {
  background-color: rgba(153, 153, 153, 0.1);
}
.version4 .widget_shopping_cart .cart_list li:hover img {
  background-color: rgba(153, 153, 153, 0.2);
}
.version4 .widget_shopping_cart p.buttons > a,
.version4 .widget_price_filter .price_slider_amount .button {
  color: #999999 !important;
  box-shadow: inset 0 0 0 2px rgba(153, 153, 153, 0.3) !important;
}
.version4 .widget_shopping_cart p.buttons > a:hover,
.version4 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version4 .star-rating:before {
  color: rgba(153, 153, 153, 0.4) !important;
}
.version4 .widget_nav_menu li a:before,
.version4 .widget_product_categories li a:before,
.version4 .widget_categories ul li a:before,
.version4 .widget_pages ul li a:before,
.version4 .widget_archive ul li a:before,
.version4 .widget_meta ul li a:before {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version4 .yawp_wim_title a {
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.version4 .widget_categories ul li,
.version4 .widget_pages ul li,
.version4 .widget_archive ul li,
.version4 .widget_meta ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.25);
}
.version4.fixed-true.active {
  height: 130px;
}
.version4.fixed-true.active .header-body {
  height: 90px;
}
.version4.fixed-true.active .logo,
.version4.fixed-true.active .logo-title,
.version4.fixed-true.active .logo:before,
.version4.fixed-true.active .logo-title:before {
  background-color: rgba(255, 255, 255, 1);
}
.version4.fixed-true.active .logo:after,
.version4.fixed-true.active .logo-title:after {
  border-color: transparent transparent transparent rgba(255, 255, 255, 1);
}
.version4.fixed-true.active .logo,
.version4.fixed-true.active .logo-title {
  height: 130px;
  line-height: 130px;
}
.version4.fixed-true.active .desk-menu .sub-menu {
  top: 90px;
}
.version4.fixed-true.active .search {
  top: 65px;
}
.version4.fixed-true.active .desk-cart-wrap {
  height: 90px;
}
.version4.fixed-true.active .search-toggle-wrap,
.version4.fixed-true.active .desk-cart-toggle,
.version4.fixed-true.active .site-sidebar-toggle {
  margin-top: 25px;
}
.version4.fixed-true.active .woo-cart {
  top: 90px;
}
.version4.fixed-true.active .desk-menu > ul > li {
  height: 90px;
  line-height: 90px;
}
.version4.fixed-true.active .desk-menu > ul > li > a {
  margin-top: 30px;
}
.version4.fixed-true.active .logo-title {
  color: #999999;
}
.version4.fixed-true.active .header-body {
  background-color: rgba(255, 255, 255, 1);
}
.version4.fixed-true.active .desk-menu > ul > li > a {
  color: #999999;
}
.version4.fixed-true.active .desk-cart-toggle span {
  color: #999999;
}
.version4.fixed-true.active .desk-menu > ul > li:hover > a,
.version4.fixed-true.active .desk-menu > ul > li.one-page-active > a,
.version4.fixed-true.active .desk-menu > ul > li.current-menu-item > a,
.version4.fixed-true.active .desk-menu > ul > li.current-menu-parent > a,
.version4.fixed-true.active .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version4.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.one-page-top.version4.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.one-page-top.version4.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  color: #999999;
}
.one-page-top.version4.fixed-true.active .desk-menu > ul > li:hover > a,
.one-page-top.version4.fixed-true.active
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  color: #999999 !important;
}
.version4.fixed-true.active .site-sidebar-toggle {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent;
}
.version4.fixed-true.active .search-true.cart-false .search-toggle:after,
.version4.fixed-true.active .cart-true .desk-cart-wrap:after {
  background-color: rgba(153, 153, 153, 0.2);
}
.version4.fixed-true.active.effect-outline .desk-menu > ul > li:hover > a,
.version4.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a,
.version4.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.version4.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.version4.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.version4.fixed-true.active.effect-underline .desk-menu > ul > li > a:after {
  bottom: -30px;
}
.one-page-top.version4.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li:hover
  > a,
.one-page-top.version4.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version4.fixed-true.active.effect-underline .desk-menu > ul > li > a:after,
.version4.fixed-true.active.effect-overline .desk-menu > ul > li > a:after,
.version4.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.version4.fixed-true.active.effect-fill .desk-menu > ul > li.one-page-active,
.version4.fixed-true.active.effect-fill .desk-menu > ul > li.current-menu-item,
.version4.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-parent,
.version4.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version4.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version4.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version5 .header-top {
  background-color: rgba(250, 250, 250, 1);
}
.version5 .header-top .header-top-menu ul li a,
.version5 .header-top .social-text {
  color: #999999;
  font-weight: normal;
  font-size: 14px;
  text-transform: none;
}
.version5 .header-top .header-top-menu ul li:hover > a {
  color: #ffb700;
}
.version5 .header-top .header-top-menu > ul > li:not(:last-child):after {
  color: rgba(153, 153, 153, 0.3);
}
.version5 .header-top .top-button {
  color: #ffffff;
  background-color: #ffb700;
}
.version5 .header-top .top-button:hover {
  background-color: rgb(235, 163, -20);
}
.version5 .header-top .header-top-social-links a {
  color: #999999 !important;
}
.version5 .header-top .header-top-menu ul li ul.submenu-languages,
.version5 .desk-menu > ul > li ul.submenu-languages {
  width: 149px;
}
.version5.top-false {
  height: 90px;
}
.version5.top-true {
  height: 170px;
}
.version5 .header-body {
  background-color: rgba(255, 255, 255, 1);
  height: 90px;
}
.desk.version5 .desk-menu {
  background-color: rgba(255, 255, 255, 1);
}
.version5 .logo,
.version5 .logo-title {
  height: 90px;
  line-height: 90px;
}
.version5 .search {
  top: 65px;
}
.version5 .desk-cart-wrap {
  height: 90px;
}
.version5 .search,
.version5 .desk-cart-toggle,
.version5 .site-sidebar-toggle {
  margin-top: 25px;
}
.version5 .site-sidebar-toggle {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px transparent;
}
.version5 .woo-cart {
  top: 130px;
}
.version5 .desk-menu > ul > li {
  margin-left: 20px !important;
}
.version5:not(.active) .logo-title {
  color: #999999;
}
.version5 .desk-menu > ul > li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}
.version5 .desk-menu > ul > li.menu-item-language > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.5);
}
.version5 .desk-menu > ul > li.menu-item-language:hover > a > .txt {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.7);
}
.version5 .desk-menu > ul > li:hover > a,
.version5 .desk-menu > ul > li.one-page-active > a,
.version5 .desk-menu > ul > li.current-menu-item > a,
.version5 .desk-menu > ul > li.current-menu-parent > a,
.version5 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version5 .desk-menu > ul > li.current-menu-item > a,
.one-page-top.version5 .desk-menu > ul > li.current-menu-parent > a,
.one-page-top.version5 .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version5 .desk-menu > ul > li:hover > a,
.one-page-top.version5 .desk-menu > ul > li.one-page-active > a {
  color: #999999 !important;
}
.version5 .desk-menu .sub-menu,
.version5 .header-top .header-top-menu ul li ul,
.version5 .woo-cart {
  background-color: #292929;
}
.version5 .search {
  background-color: #292929 !important;
}
.version5 .desk-menu .sub-menu .sub-menu {
  background-color: rgb(21, 21, 21);
}
.version5 .desk-menu .sub-menu li > a {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version5 .header-top .header-top-menu ul li ul li a {
  color: #999999;
}
.version5 .desk-menu .sub-menu li:hover > a,
.version5 .header-top .header-top-menu ul li ul li:hover > a {
  color: #e2e2e2;
}
.version5 .desk-menu [data-mm="true"] > .sub-menu > li > a {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version5
  .desk-menu
  > ul
  > [data-mm="true"]
  > .sub-menu
  > li:not(:last-child):after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.version5 .desk-menu .sub-menu .label {
  font-family: Arial, Helvetica, sans-serif;
}
.version5 .search-true.cart-false .search-toggle:after,
.version5 .cart-true .desk-cart-wrap:after {
  background-color: rgba(153, 153, 153, 0.2);
}
.version5 .search input[type="text"] {
  color: #999999;
}
.version5 .desk-cart-toggle span {
  color: #999999;
}
.version5 .my-cart {
  color: #ffb700 !important;
}
.version5.effect-underline .desk-menu > ul > li > a:after,
.version5.effect-overline .desk-menu > ul > li > a:after,
.version5.effect-fill .desk-menu > ul > li:hover,
.version5.effect-fill .desk-menu > ul > li.one-page-active > a,
.version5.effect-fill .desk-menu > ul > li.current-menu-item,
.version5.effect-fill .desk-menu > ul > li.current-menu-parent,
.version5.effect-fill .desk-menu > ul > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version5.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version5.effect-fill .desk-menu > ul > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version5.effect-outline .desk-menu > ul > li:hover > a,
.version5.effect-outline .desk-menu > ul > li.one-page-active > a,
.version5.effect-outline .desk-menu > ul > li.current-menu-item > a,
.version5.effect-outline .desk-menu > ul > li.current-menu-parent > a,
.version5.effect-outline .desk-menu > ul > li.current-menu-ancestor > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.one-page-top.version5.effect-outline .desk-menu > ul > li:hover > a,
.one-page-top.version5.effect-outline .desk-menu > ul > li.one-page-active > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version5.effect-overline .desk-menu > ul > li > a:after {
  top: -30px;
}
.version5 .search input[type="text"],
.version5 .search input[type="text"]:hover {
  box-shadow: inset 0 0 0 1px #eeeeee !important;
  color: #999999 !important;
}
.version5 .menu-item-type-yawp_wim,
.version5 .menu-item-type-yawp_wim a:not(.button) {
  color: #999999;
  text-transform: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
}
.version5 .widget_rss a,
.version5 .widget_nz_recent_entries a,
.version5 .widget_recent_comments a,
.version5 .widget_recent_entries a,
.version5 .widget_nz_recent_entries .post .post-date,
.version5 .nz-schedule li,
.version5 .widget_twitter .tweet-time,
.version5 .widget_shopping_cart .cart_list > li > a {
  color: #999999 !important;
}
.version5 .widget_rss a:hover,
.version5 .widget_nz_recent_entries a:hover,
.version5 .widget_recent_comments a:hover,
.version5 .widget_recent_entries a:hover,
.version5 .widget_shopping_cart .cart_list > li > a:hover {
  color: #e2e2e2 !important;
}
.version5 .widget_twitter ul li:before,
.version5 .widget_nz_recent_entries .post-body:before,
.version5 .widget_recent_comments ul li:before {
  color: #999999 !important;
  background-color: #ffb700 !important;
}
.version5 .nz-schedule ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version5 .widget_shopping_cart .cart_list > li,
.version5 .widget_products .product_list_widget > li,
.version5 .widget_recently_viewed_products .product_list_widget > li,
.version5 .widget_recent_reviews .product_list_widget > li,
.version5 .widget_top_rated_products .product_list_widget > li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.2);
}
.version5 .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: rgba(153, 153, 153, 0.2) !important;
}
.version5 .widget_calendar td#prev,
.version5 .widget_calendar td#next,
.version5 .widget_calendar td,
.version5 .widget_calendar caption,
.version5 .widget_calendar th:first-child,
.version5 .widget_calendar th:last-child {
  border-color: rgba(153, 153, 153, 0.2);
}
.version5 .widget_tag_cloud .tagcloud a:hover,
.version5 .widget_product_tag_cloud .tagcloud a:hover,
.version5 .post-tags a:hover,
.version5 .projects-tags a:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version5 .widget_calendar td#today {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version5 .widget_tag_cloud .tagcloud a:hover,
.version5 .widget_product_tag_cloud .tagcloud a:hover,
.version5 .post-tags a:hover,
.version5 .projects-tags a:hover,
.version5 .widget_tag_cloud .tagcloud a:hover,
.version5 .widget_product_tag_cloud .tagcloud a:hover,
.version5 .post-tags a:hover,
.version5 .projects-tags a:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version5 .widget_nav_menu ul li:hover a {
  color: #999999 !important;
}
.version5 .widget_product_categories ul li a:hover,
.version5 .widget_shopping_cart p.buttons > a:hover,
.version5 .widget_recent_entries a:hover,
.version5 .widget_categories ul li a:hover,
.version5 .widget_pages ul li a:hover,
.version5 .widget_archive ul li a:hover,
.version5 .widget_meta ul li a:hover,
.version5 .widget_nz_recent_entries .post-title:hover,
.version5 .widget_price_filter .price_slider_amount .button:hover,
.version5 .widget_shopping_cart .cart_list > li > a:hover,
.version5 .widget_products .product_list_widget > li > a:hover,
.version5 .widget_recently_viewed_products .product_list_widget > li > a:hover,
.version5 .widget_recent_reviews .product_list_widget > li > a:hover,
.version5 .widget_top_rated_products .product_list_widget > li > a:hover,
.version5 .widget_recent_entries a:hover,
.version5 .widget_nz_recent_entries .post-title:hover,
.version5 .widget_product_categories ul li a:hover,
.version5 .widget_nav_menu ul li a:hover,
.version5 .widget_recent_comments a:hover {
  color: #e2e2e2 !important;
}
.version5 .menu-item-type-yawp_wim a:hover {
  color: #e2e2e2;
}
.version5 textarea,
.version5 select,
.version5 input[type="date"],
.version5 input[type="datetime"],
.version5 input[type="datetime-local"],
.version5 input[type="email"],
.version5 input[type="month"],
.version5 input[type="number"],
.version5 input[type="password"],
.version5 input[type="search"],
.version5 input[type="tel"],
.version5 input[type="text"],
.version5 input[type="time"],
.version5 input[type="url"],
.version5 input[type="week"] {
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.25);
  color: #999999;
}
.version5 textarea:hover,
.version5 select:hover,
.version5 input[type="date"]:hover,
.version5 input[type="datetime"]:hover,
.version5 input[type="datetime-local"]:hover,
.version5 input[type="email"]:hover,
.version5 input[type="month"]:hover,
.version5 input[type="number"]:hover,
.version5 input[type="password"]:hover,
.version5 input[type="search"]:hover,
.version5 input[type="tel"]:hover,
.version5 input[type="text"]:hover,
.version5 input[type="time"]:hover,
.version5 input[type="url"]:hover,
.version5 input[type="week"]:hover {
  color: #e2e2e2 !important;
}
.version5 .desk-menu .button,
.version5 .desk-menu button,
.version5 .desk-menu input[type="reset"],
.version5 .desk-menu input[type="submit"],
.version5 .desk-menu input[type="button"] {
  background-color: #ffb700 !important;
  color: #ffffff !important;
}
.version5 .desk-menu .button:hover,
.version5 .desk-menu button:hover,
.version5 .desk-menu input[type="reset"]:hover,
.version5 .desk-menu input[type="submit"]:hover,
.version5 .desk-menu input[type="button"]:hover {
  background-color: rgb(235, 163, -20) !important;
}
.version5 ::-webkit-input-placeholder {
  color: #999999;
}
.version5 :-moz-placeholder {
  color: #999999;
}
.version5 ::-moz-placeholder {
  color: #999999;
}
.version5 :-ms-input-placeholder {
  color: #999999;
}
.version5 .widget_price_filter .ui-slider .ui-slider-handle {
  border-color: rgba(255, 183, 0, 1) !important;
}
.version5 .widget_shopping_cart p.buttons > a:hover,
.version5 .widget_shopping_cart p.buttons > a:hover,
.version5 .widget_price_filter .price_slider_amount .button:hover {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version5 .widget_price_filter .ui-slider .ui-slider-range {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version5 .widget_shopping_cart p.buttons > a:hover,
.version5 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version5 .yawp_wim_title {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.version5 .widget_tag_cloud .tagcloud a,
.version5 .post-tags a,
.version5 .widget_product_tag_cloud .tagcloud a,
.version5 .projects-tags a {
  font-family: Arial, Helvetica, sans-serif;
  color: #999999;
  box-shadow: inset 0 0 0 1px rgba(153, 153, 153, 0.3) !important;
}
.version5 .widget_shopping_cart {
  color: #999999;
}
.version5 .widget_shopping_cart .cart_list li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.version5 .widget_shopping_cart .cart_list li .remove {
  background-color: rgb(31, 31, 31) !important;
}
.version5 .widget_shopping_cart .cart_list li .remove:hover {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version5 .widget_shopping_cart .cart_list li img {
  background-color: rgba(153, 153, 153, 0.1);
}
.version5 .widget_shopping_cart .cart_list li:hover img {
  background-color: rgba(153, 153, 153, 0.2);
}
.version5 .widget_shopping_cart p.buttons > a,
.version5 .widget_price_filter .price_slider_amount .button {
  color: #999999 !important;
  box-shadow: inset 0 0 0 2px rgba(153, 153, 153, 0.3) !important;
}
.version5 .widget_shopping_cart p.buttons > a:hover,
.version5 .widget_price_filter .price_slider_amount .button:hover {
  color: rgba(255, 183, 0, 1) !important;
}
.version5 .star-rating:before {
  color: rgba(153, 153, 153, 0.4) !important;
}
.version5 .widget_nav_menu li a:before,
.version5 .widget_product_categories li a:before,
.version5 .widget_categories ul li a:before,
.version5 .widget_pages ul li a:before,
.version5 .widget_archive ul li a:before,
.version5 .widget_meta ul li a:before {
  background-color: rgba(255, 183, 0, 1) !important;
}
.version5 .yawp_wim_title a {
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.version5 .widget_categories ul li,
.version5 .widget_pages ul li,
.version5 .widget_archive ul li,
.version5 .widget_meta ul li {
  border-bottom: 1px dashed rgba(153, 153, 153, 0.25);
}
.version5.desk.fixed-true.active {
  -webkit-transform: translateY(-90px);
  -ms-transform: translateY(-90px);
  transform: translateY(-90px);
}
.version5.desk.fixed-true.top-true.active {
  -webkit-transform: translateY(-130px);
  -ms-transform: translateY(-130px);
  transform: translateY(-130px);
}
.version5.fixed-true.active .desk-menu {
  background-color: rgba(255, 255, 255, 1);
}
.version5.fixed-true.active .desk-menu > ul > li > a {
  color: #999999;
}
.version5.fixed-true.active .desk-menu > ul > li:hover > a,
.version5.fixed-true.active .desk-menu > ul > li.one-page-active > a,
.version5.fixed-true.active .desk-menu > ul > li.current-menu-item > a,
.version5.fixed-true.active .desk-menu > ul > li.current-menu-parent > a,
.version5.fixed-true.active .desk-menu > ul > li.current-menu-ancestor > a {
  color: #999999;
}
.one-page-top.version5.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.one-page-top.version5.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.one-page-top.version5.fixed-true.active
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  color: #999999;
}
.one-page-top.version5.fixed-true.active .desk-menu > ul > li:hover > a,
.one-page-top.version5.fixed-true.active
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  color: #999999 !important;
}
.version5.fixed-true.active.effect-outline .desk-menu > ul > li:hover > a,
.version5.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a,
.version5.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-item
  > a,
.version5.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-parent
  > a,
.version5.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.current-menu-ancestor
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1);
}
.one-page-top.version5.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li:hover
  > a,
.one-page-top.version5.fixed-true.active.effect-outline
  .desk-menu
  > ul
  > li.one-page-active
  > a {
  box-shadow: inset 0 0 0 2px rgba(255, 183, 0, 1) !important;
}
.version5.fixed-true.active.effect-underline .desk-menu > ul > li > a:after,
.version5.fixed-true.active.effect-overline .desk-menu > ul > li > a:after,
.version5.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.version5.fixed-true.active.effect-fill .desk-menu > ul > li.one-page-active,
.version5.fixed-true.active.effect-fill .desk-menu > ul > li.current-menu-item,
.version5.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-parent,
.version5.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.current-menu-ancestor {
  background-color: rgba(255, 183, 0, 1);
}
.one-page-top.version5.fixed-true.active.effect-fill .desk-menu > ul > li:hover,
.one-page-top.version5.fixed-true.active.effect-fill
  .desk-menu
  > ul
  > li.one-page-active {
  background-color: rgba(255, 183, 0, 1) !important;
}
@media only screen and (min-width: 1100px) {
  .version1.fixed-true.stuck-false.stuck-boxed-false + .page-content-wrap {
    padding-top: 90px;
  }
  .version1.fixed-true.stuck-false.stuck-boxed-false.top-true
    + .page-content-wrap {
    padding-top: 130px;
  }
  .version1.fixed-true.active.stuck-boxed-false.stuck-false
    + .page-content-wrap {
    padding-top: 90px;
  }
  .version1.stuck-true + .page-content-wrap > .rich-header.version1 {
    height: 190px;
  }
  .version1.stuck-true.top-true + .page-content-wrap > .rich-header.version1 {
    height: 230px;
  }
  .version1.stuck-boxed-true + .page-content-wrap > .rich-header.version1 {
    height: 190px;
  }
  .version1.stuck-boxed-true.top-true
    + .page-content-wrap
    > .rich-header.version1 {
    height: 230px;
  }
  .version1.stuck-true + .page-content-wrap > .rich-header.version2 {
    height: 290px;
  }
  .version1.stuck-true.top-true + .page-content-wrap > .rich-header.version2 {
    height: 330px;
  }
  .version1.stuck-boxed-true + .page-content-wrap > .rich-header.version2 {
    height: 290px;
  }
  .version1.stuck-boxed-true.top-true
    + .page-content-wrap
    > .rich-header.version2 {
    height: 330px;
  }
  .version1.stuck-true + .page-content-wrap > .rich-header.version1 {
    padding-top: 104px;
  }
  .version1.stuck-true.top-true + .page-content-wrap > .rich-header.version1 {
    padding-top: 144px;
  }
  .version1.stuck-boxed-true + .page-content-wrap > .rich-header.version1 {
    padding-top: 104px;
  }
  .version1.stuck-boxed-true.top-true
    + .page-content-wrap
    > .rich-header.version1 {
    padding-top: 144px;
  }
  .version1.stuck-true + .page-content-wrap > .rich-header.version2 {
    padding-top: 104px;
  }
  .version1.stuck-true.top-true + .page-content-wrap > .rich-header.version2 {
    padding-top: 144px;
  }
  .version1.stuck-boxed-true + .page-content-wrap > .rich-header.version2 {
    padding-top: 104px;
  }
  .version1.stuck-boxed-true.top-true
    + .page-content-wrap
    > .rich-header.version2 {
    padding-top: 144px;
  }
  .version2.fixed-true.stuck-false + .page-content-wrap {
    padding-top: 90px;
  }
  .version2.fixed-true.stuck-false.top-true + .page-content-wrap {
    padding-top: 130px;
  }
  .version2.fixed-true.active.stuck-false + .page-content-wrap {
    padding-top: 90px;
  }
  .version2.stuck-true + .page-content-wrap > .rich-header.version1 {
    height: 190px;
  }
  .version2.stuck-true.top-true + .page-content-wrap > .rich-header.version1 {
    height: 230px;
  }
  .version2.stuck-true + .page-content-wrap > .rich-header.version2 {
    height: 290px;
  }
  .version2.stuck-true.top-true + .page-content-wrap > .rich-header.version2 {
    height: 330px;
  }
  .version2.stuck-true + .page-content-wrap > .rich-header.version1 {
    padding-top: 104px;
  }
  .version2.stuck-true.top-true + .page-content-wrap > .rich-header.version1 {
    padding-top: 144px;
  }
  .version2.stuck-true + .page-content-wrap > .rich-header.version2 {
    padding-top: 104px;
  }
  .version2.stuck-true.top-true + .page-content-wrap > .rich-header.version2 {
    padding-top: 144px;
  }
  .fixed-true.header-version3.page-content-wrap:not(.revolution-slider-active) {
    padding-top: 90px;
  }
  .fixed-true.header-version3.page-content-wrap.active {
    padding-top: 90px;
  }
  .version3.fixed-true.active.effect-overline .desk-menu > ul > li > a:after {
    top: -30px;
  }
  .version4.fixed-true.stuck-boxed-false + .page-content-wrap {
    padding-top: 110px;
  }
  .version4.fixed-true.active.stuck-boxed-false + .page-content-wrap {
    padding-top: 130px;
  }
  .version4.stuck-boxed-true + .page-content-wrap > .rich-header.version1 {
    height: 210px;
  }
  .version4.stuck-boxed-true + .page-content-wrap > .rich-header.version2 {
    height: 320px;
  }
  .version4.stuck-boxed-true + .page-content-wrap > .rich-header.version1 {
    padding-top: 124px;
  }
  .version4.stuck-boxed-true + .page-content-wrap > .rich-header.version2 {
    padding-top: 124px;
  }
  .version5.fixed-true.stuck-false.stuck-boxed-false + .page-content-wrap {
    padding-top: 130px;
  }
  .version5.fixed-true.stuck-false.stuck-boxed-false.top-true
    + .page-content-wrap {
    padding-top: 170px;
  }
  .version5.fixed-true.active.stuck-boxed-false.stuck-false
    + .page-content-wrap {
    padding-top: 130px;
  }
  .version5.stuck-true + .page-content-wrap > .rich-header.version1 {
    height: 230px;
  }
  .version5.stuck-true.top-true + .page-content-wrap > .rich-header.version1 {
    height: 270px;
  }
  .version5.stuck-boxed-true + .page-content-wrap > .rich-header.version1 {
    height: 240px;
  }
  .version5.stuck-boxed-true.top-true
    + .page-content-wrap
    > .rich-header.version1 {
    height: 280px;
  }
  .version5.stuck-true + .page-content-wrap > .rich-header.version2 {
    height: 330px;
  }
  .version5.stuck-true.top-true + .page-content-wrap > .rich-header.version2 {
    height: 370px;
  }
  .version5.stuck-boxed-true + .page-content-wrap > .rich-header.version2 {
    height: 330px;
  }
  .version5.stuck-boxed-true.top-true
    + .page-content-wrap
    > .rich-header.version2 {
    height: 380px;
  }
  .version5.stuck-true + .page-content-wrap > .rich-header.version1 {
    padding-top: 144px;
  }
  .version5.stuck-true.top-true + .page-content-wrap > .rich-header.version1 {
    padding-top: 184px;
  }
  .version5.stuck-boxed-true + .page-content-wrap > .rich-header.version1 {
    padding-top: 144px;
  }
  .version5.stuck-boxed-true.top-true
    + .page-content-wrap
    > .rich-header.version1 {
    padding-top: 184px;
  }
  .version5.stuck-true + .page-content-wrap > .rich-header.version2 {
    padding-top: 144px;
  }
  .version5.stuck-true.top-true + .page-content-wrap > .rich-header.version2 {
    padding-top: 184px;
  }
  .version5.stuck-boxed-true + .page-content-wrap > .rich-header.version2 {
    padding-top: 144px;
  }
  .version5.stuck-boxed-true.top-true
    + .page-content-wrap
    > .rich-header.version2 {
    padding-top: 184px;
  }
}
@media only screen and (min-width: 1600px) {
  .version1 .desk-menu > ul > li {
    margin-left: 20px !important;
  }
  .version2 .desk-menu > ul > li {
    margin-left: 20px !important;
  }
  .version3 .desk-menu > ul > li {
    margin-left: 20px !important;
  }
  .version4 .desk-menu > ul > li {
    margin-left: 20px !important;
  }
}

@media (max-width: 1100px) {
  .version4 .desk-menu > ul > li > a {
    margin-top: 0 !important;
    margin-top: 0 !important;
    height: 56px !important;

    display: flex;
    align-items: center;
  }

  .version4 .header-top {
    padding: 1rem 0;
  }

  .version4 .header-top .social-text {
    margin-right: 1rem;
  }
  .header .logo a {
    margin-bottom: 1px #808080 solid !important;
  }
  .desk-menu > ul > li > a {
    margin-top: 0px !important;
  }
}
.footer-main-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 4rem;
}

.footer-column p{
  color: #fff;
}
.footer-column span{
  color: #fff;
}
.quick-linkss li a:hover {
  color: #1e73be !important;
}
@media(max-width:1174px){
    .footer-main-wrapper {
      
        grid-template-columns: 1fr 1fr ;
        
      }
}
@media(max-width:732px) {
  .footer-main-wrapper {

    grid-template-columns: 1fr;

  }
}
.quick-linkss li a {
  color: #ffffff !important;
  transition: 0.5s;
}

.footer-header h2 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 1rem;
}
.contact-wrapper-address {
  display: grid;
  /* grid-template-columns: 4fr 4fr; */
  column-gap: 4rem;
}

.footer .swiper-slide {
  text-align: left;
  background: none;
}
.footer .swiper {
  height: auto !important;
}
/* .footer p {
  color: #fff;
} */
.footer-bottom {
  padding: 1rem 0;
  background: #202020;
  text-align: center;
}
.footer-bottom p {
  margin-bottom: 0;
  color: #fff;
}

@import "media-query";

.servicesIndex {
  padding: 4rem 2rem;
  background: #f4f4f4;
  overflow-x: hidden;
  .title-bar {
    text-align: center;

    h2 {
      text-align: center;
      color: #808080;
      font-size: 2rem;
      font-weight: 440;
      padding: 0.5rem;
      font-family: "Lato", sans-serif;
    }
    &:after {
      content: "";
      display: block;
      margin: 0 auto;

      margin-bottom: 1rem;
      bottom: 0;
      width: 10%;
      height: 3px;
      background: #0795b2;
    }
  }

  .mainContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 4rem;
    padding: 2rem 0;

    .mainServiceCard {
      text-align: center;
      transition: 0.5s;
      padding: 1rem 1rem;
      border-radius: 2px;
      display: flex;
      align-items: center;
      flex-direction: column;
      h3 {
        font-size: 22px;
        font-weight: 550;
        position: relative;

        &::after {
          content: "";
          display: block;

          margin: 0 auto;
          margin-top: 1rem;
          margin-bottom: 1rem;
          bottom: 0;
          width: 20%;
          height: 2px;
          background: #000;
        }
      }

      .readMoreBtn {
        a {
          color: #0795b2;
        }
      }

      &:hover {
        background: #0795b2;

        p {
          font-size: 16px;
          color: #fff !important;
        }

        h3 {
          color: #fff;

          &::after {
            content: "";

            background: #fff;
          }
        }

        .readMoreBtn {
          a {
            color: #fff;
          }
        }
      }

      img {
        margin-bottom: 1rem;
        max-width: 5rem !important;
      }
    }
  }

  @include tablet {
    .mainContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 5rem;
      row-gap: 4rem;
    }
  }

  @include mobile {
    .mainContainer {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 3rem;
      row-gap: 4rem;
    }
  }
}

.accordion {
  position: relative;

  h2 {
    padding: 0;
  }

  button {
    margin: 0;
    font-size: 2px;

    .collapsed {
      font-size: 14px !important;
    }
  }
}

.our-sv-wrapper-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
}

@include tablet {
  .our-sv-wrapper-inner {
    grid-template-columns: 1fr 1fr !important;
  }
}

@include mobile {
  .our-sv-wrapper-inner {
    grid-template-columns: 1fr;
  }
}

.home-accordion {
  display: block;
  width: auto;
  overflow-x: hidden;
  .accordion-content {
    max-width: 85%;
    padding: 0 1rem;
    padding: 2rem;

    @include tablet {
      max-width: 100%;
    }
    .accordion-header {
      padding-left: 0;
      span {
        font-size: 16px;
      }
      h2 {
        margin-bottom: 3rem;
      }
      button {
        padding: 0;
        background: none;

        p {
          margin-bottom: 0 !important;
        }
      }
    }
    .accordion-header {
      button {
        p {
          font-size: 16px !important;
          font-weight: 550;
        }
      }
    }

    .accordion-body {
      padding-left: 0;
      border: none;
    }
    .accordion {
      .accordion-item {
        border: none;
        padding-bottom: 1rem !important;
        margin-bottom: 1rem;
        border-bottom: 1px solid rgb(136 136 153 / 16%);

        &:last-child {
          border: none;
        }
        &:first-of-type {
          button {
            border: none;
            box-shadow: none;
          }
        }
        button {
          border: none;
          border: none;
          box-shadow: none;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
.accordion-button:not(.collapsed) {
  border: none;
  background-color: none !important;
}
//Home about us

.HomeAbout {
  padding: 4rem 0;
  .abt-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;

    @include tablet {
      grid-template-columns: 1fr;
    }

    .aboutus-content {
      padding-right: 2rem;
      .abt-title-strip {
        border-left: 2px solid #0795b2;
        margin-bottom: 60px;

        padding: 0.2rem 2rem;
        h1 {
          font-family: "Lato", sans-serif;

          font-size: 30px;
          font-weight: 400;
        }
      }

      p {
        font-size: 18px;
        line-height: 1.8;
      }
      .abt-read-more {
        padding: 2rem 0;

        a {
          padding: 0.5rem 2rem;
          color: #fff;
          background: #0795b2;
          transition: 0.5s;
          &:hover {
            background: #ffb900;
          }
        }
      }
    }
    .abt-img {
      img {
        max-width: 100% !important;
      }
    }
  }
}

.mission-vission {
  position: relative;
  .mission-vission-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include tablet {
      grid-template-columns: 1fr;
    }

    .mission-card,
    .vision-card {
      background: #fbf7f7;
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      min-height: 400px;

      &:last-child {
        background: #0795b2;
        .content {
          h2 {
            font-size: 26px;
            color: #fff;
          }
          p {
            color: #fff;
          }
          .read--btn {
            margin-top: 2rem;
            a {
              color: #fff;
              margin-top: 3rem;
            }
          }
        }
      }
      .icon {
        margin-bottom: 2rem;

        top: 0;
        img {
          &.mission-icon {
            max-height: 12rem;
          }
        }
      }
      .content {
        h2 {
          font-size: 26px;
        }
        .read--btn {
          margin-top: 2rem;

          a {
            color: #000;
            margin-top: 3rem;
          }
        }
      }
    }
  }
}

.under-strip {
  .strip-wrapper {
    text-transform: capitalize;
    .strip-card {
      padding: 0;
      button {
        background: none;
        color: #ffb900;
        text-transform: capitalize !important;
        font-weight: lighter;
      }
    }
  }
}

.navbar-expand-lg {
  &.bg-body-tertiary {
    background-color: #fff !important;
  }

  .navbar-collapse {
    justify-content: flex-end;
    padding-top: 10px;
    a {
      &.nav-link {
        padding-left: 35px;
        @include tablet {
          padding-left: 0 !important;
        }
      }
    }
  }
}

.desk.version4 .logo,
.desk.version4 .logo-title,
.desk.version4 .logo-title:before,
.desk.version4 .logo:before {
  background-color: #fff;
}

@media (min-width: 990px) {
  .version4 {
    .header-top {
      background: #fff !important;
    }
  }

  .header-top::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(202, 162, 105, 1);
    -webkit-clip-path: polygon(27% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 30% 100%);
  }

  #html-body {
    .navbar {
      a {
        &.navbar-brand {
          position: absolute;
          top: -42px;
          z-index: 1212;
        }
      }

      .navDropdown {
        transition: 1s;

        &:hover {
          .dropdown-menu {
            display: block !important;
            visibility: visible !important;
            opacity: 1 !important;
            display: none;
          }
        }

        .dropdown-menu {
          background: #212529;
          transform: translateY(40);
          opacity: 0;
          transition: 0.5s;
          display: block !important;
          visibility: hidden;

          &:hover {
            display: block !important;
            opacity: 1;
            top: 40px;
            transition: 0.2s ease-in;
            visibility: visible;
          }
          &.show {
            opacity: 1;
            top: 40px;
            transition: 0.2s ease-in;
            visibility: visible;
          }
          a {
            color: #fff;
            font-size: 16px;
            padding: 0.6rem 1rem;

            &.dropdown-item {
              &:hover {
                background: #0795b2;
              }
              &:focus {
                background: #0795b2;
              }
            }
          }
        }
      }
    }
  }
}

.row-grid-unique {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.contactAccordion {
  .accordion-button {
    background: #212529;
    font-size: 16px;
    &::after {
      color: #fff !important;
      z-index: 12122;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.footer-column {
  .footer-header {
    h2 {
      &:after {
        content: "";
        width: 30%;
        height: 2px;
        background: #fff;
        display: block;

        &:nth-child(2) {
          display: none;
        }
      }

      &:nth-child(2) {
        &::after {
          display: none !important;
        }
      }
    }
  }
  .contact-wrapper-address {
    h4 {
      color: #fff;
    }
    p {
      color: #fff !important;
    }
    ul {
      li {
        div {
          display: inline-flex;
          i {
            color: #fff;
            margin-right: 0.5rem;
            margin-top: 0.3rem;
            margin-right: 1rem;

            &.fas {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.footer-column {
  &:nth-child(2) {
    text-align: center;
    @include tablet {
      text-align: left;
    }
    h2 {
      &::after {
        position: relative;
        left: 50%;
        transform: translate(-50%);
        @include tablet {
          position: static;
          left: auto;
          transform: inherit;
        }
      }
    }
  }
}

footer {
  .footer-main-wrapper {
    .footer-header {
      color: #fff !important;

      p {
        h3,
        h5 {
          color: #fff;
          font-weight: lighter;
          font-size: 16px;
        }
      }
    }
    .footer-column {
      .footer-header {
        ul {
          li {
            list-style: none;
            color: #fff !important;
          }
        }
      }
    }
    .swiper-slide {
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      margin-right: 0;
      color: #fff;
    }
  }
}
footer {
  .footer-main-wrapper {
    .footer-header {
      color: #fff !important;

      p {
        h3,
        h5 {
          color: #fff;
          font-weight: lighter;
          font-size: 16px;
        }
      }
    }
    .footer-column {
      .footer-header {
        ul {
          li {
            list-style: none;
          }
        }
      }
      .contact-wrapper-address {
        ul {
          max-width: 300px;

          li {
            display: flex;
            align-items: baseline;
          }
        }
      }
    }
    .swiper-slide {
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      margin-right: 0;
      color: #fff;
    }
  }
}

.banner-swipering {
  .BannerOvarlay {
    width: 100%;
    height: 100vh;
    background: #00000063;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1111;
  }
  .banner-content {
    z-index: 11212;
  }
}

//modal

.modal-dialog {
  .modal-body {
    a.btn {
      padding: 0.2rem 0.6rem;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      background-color: #5a6268;
      border-color: #545b62;
      color: #fff;
      border-radius: 30px;
      min-width: 100% !important;
      margin-bottom: 10px;
    }
  }
}
.dropdown-menu {
  border: none;
  padding-left: 1rem;
  background: #212529;
  margin-left: 0 !important;

  a {
    font-size: 14px;
    color: #fff;
  }
}

@media (min-width: 990px) {
  .isSticky {
    position: fixed;
    right: 0;
    z-index: 12122;
    bottom: 0;
    width: 100%;
    transition: 0.5s ease-in;
  }
}
.swiper {
  @include tablet {
    height: auto;
  }
  .swiper-slide {
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }
  }
}

.contact-address-wrapper {
  .contact-box {
    &::after {
      content: "";
      position: relative;
      width: 2px;
      height: 100%;
      background: #fff;
    }
  }
}

.navbar {
  .navbar-brand {
    @include mobile {
      max-width: 5rem;
    }
  }
}

.call-to-action {
  .container {
    &.nz-clearfix {
      @include mobile {
        span {
          font-size: 14px;
        }
      }
      .icon-bulb {
        &::before {
          font-size: 42px;
        }
      }
    }
  }
}

.inner-about.inner-content {
  padding: 75px;
  @include mobile {
    .nz-section {
      margin-top: 0 !important;
    }
  }
}

.contactAccordion {
  .contact-address-wrapper {
    margin: 2rem 0;
    &:nth-child(3n + 2) {
      border-left: 1px solid #ffffff3d;
      border-right: 1px solid #ffffff3d;
      padding-left: 2rem;
    }
    @include tablet {
      border: none !important;
      padding-left: 0 !important;
    }

    &:last-child {
      border-right: 0;
    }
  }
}

@mixin tablet {
  @media screen and (max-width: 990px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 482px) {
    @content;
  }
}

.servicesIndex {
  padding: 4rem 2rem;
  background: #f4f4f4;
  overflow-x: hidden;
}
.servicesIndex .title-bar {
  text-align: center;
}
.servicesIndex .title-bar h2 {
  text-align: center;
  color: #808080;
  font-size: 2rem;
  font-weight: 440;
  padding: 0.5rem;
  font-family: "Lato", sans-serif;
}
.servicesIndex .title-bar:after {
  content: "";
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
  bottom: 0;
  width: 10%;
  height: 3px;
  background: #0795b2;
}
.servicesIndex .mainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  row-gap: 4rem;
  padding: 2rem 0;
}
.servicesIndex .mainContainer .mainServiceCard {
  text-align: center;
  transition: 0.5s;
  padding: 1rem 1rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.servicesIndex .mainContainer .mainServiceCard h3 {
  font-size: 22px;
  font-weight: 550;
  position: relative;
}
.servicesIndex .mainContainer .mainServiceCard h3::after {
  content: "";
  display: block;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  bottom: 0;
  width: 20%;
  height: 2px;
  background: #000;
}
.servicesIndex .mainContainer .mainServiceCard .readMoreBtn a {
  color: #0795b2;
}
.servicesIndex .mainContainer .mainServiceCard:hover {
  background: #0795b2;
}
.servicesIndex .mainContainer .mainServiceCard:hover p {
  font-size: 16px;
  color: #fff !important;
}
.servicesIndex .mainContainer .mainServiceCard:hover h3 {
  color: #fff;
}
.servicesIndex .mainContainer .mainServiceCard:hover h3::after {
  content: "";
  background: #fff;
}
.servicesIndex .mainContainer .mainServiceCard:hover .readMoreBtn a {
  color: #fff;
}
.servicesIndex .mainContainer .mainServiceCard img {
  margin-bottom: 1rem;
  max-width: 5rem !important;
}
@media screen and (max-width: 990px) {
  .servicesIndex .mainContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 5rem;
         column-gap: 5rem;
    row-gap: 4rem;
  }
}
@media screen and (max-width: 482px) {
  .servicesIndex .mainContainer {
    display: grid;
    grid-template-columns: 1fr;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
    row-gap: 4rem;
  }
}

.accordion {
  position: relative;
}
.accordion h2 {
  padding: 0;
}
.accordion button {
  margin: 0;
  font-size: 2px;
}
.accordion button .collapsed {
  font-size: 14px !important;
}

.our-sv-wrapper-inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}

@media screen and (max-width: 990px) {
  .our-sv-wrapper-inner {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media screen and (max-width: 482px) {
  .our-sv-wrapper-inner {
    grid-template-columns: 1fr;
  }
}
.home-accordion {
  display: block;
  width: auto;
  overflow-x: hidden;
}
.home-accordion .accordion-content {
  max-width: 85%;
  padding: 0 1rem;
  padding: 2rem;
}
@media screen and (max-width: 990px) {
  .home-accordion .accordion-content {
    max-width: 100%;
  }
}
.home-accordion .accordion-content .accordion-header {
  padding-left: 0;
}
.home-accordion .accordion-content .accordion-header span {
  font-size: 16px;
}
.home-accordion .accordion-content .accordion-header h2 {
  margin-bottom: 3rem;
}
.home-accordion .accordion-content .accordion-header button {
  padding: 0;
  background: none;
}
.home-accordion .accordion-content .accordion-header button p {
  margin-bottom: 0 !important;
}
.home-accordion .accordion-content .accordion-header button p {
  font-size: 16px !important;
  font-weight: 550;
}
.home-accordion .accordion-content .accordion-body {
  padding-left: 0;
  border: none;
}
.home-accordion .accordion-content .accordion .accordion-item {
  border: none;
  padding-bottom: 1rem !important;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(136, 136, 153, 0.16);
}
.home-accordion .accordion-content .accordion .accordion-item:last-child {
  border: none;
}
.home-accordion .accordion-content .accordion .accordion-item:first-of-type button {
  border: none;
  box-shadow: none;
}
.home-accordion .accordion-content .accordion .accordion-item button {
  border: none;
  border: none;
  box-shadow: none;
}
.home-accordion .accordion-content .accordion .accordion-item button:focus {
  outline: none;
}

.accordion-button:not(.collapsed) {
  border: none;
  background-color: none !important;
}

.HomeAbout {
  padding: 4rem 0;
}
.HomeAbout .abt-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
@media screen and (max-width: 990px) {
  .HomeAbout .abt-row {
    grid-template-columns: 1fr;
  }
}
.HomeAbout .abt-row .aboutus-content {
  padding-right: 2rem;
}
.HomeAbout .abt-row .aboutus-content .abt-title-strip {
  border-left: 2px solid #0795b2;
  margin-bottom: 60px;
  padding: 0.2rem 2rem;
}
.HomeAbout .abt-row .aboutus-content .abt-title-strip h1 {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 400;
}
.HomeAbout .abt-row .aboutus-content p {
  font-size: 18px;
  line-height: 1.8;
}
.HomeAbout .abt-row .aboutus-content .abt-read-more {
  padding: 2rem 0;
}
.HomeAbout .abt-row .aboutus-content .abt-read-more a {
  padding: 0.5rem 2rem;
  color: #fff;
  background: #0795b2;
  transition: 0.5s;
}
.HomeAbout .abt-row .aboutus-content .abt-read-more a:hover {
  background: #ffb900;
}
.HomeAbout .abt-row .abt-img img {
  max-width: 100% !important;
}

.mission-vission {
  position: relative;
}
.mission-vission .mission-vission-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 990px) {
  .mission-vission .mission-vission-wrapper {
    grid-template-columns: 1fr;
  }
}
.mission-vission .mission-vission-wrapper .mission-card,
.mission-vission .mission-vission-wrapper .vision-card {
  background: #fbf7f7;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  min-height: 400px;
}
.mission-vission .mission-vission-wrapper .mission-card:last-child,
.mission-vission .mission-vission-wrapper .vision-card:last-child {
  background: #0795b2;
}
.mission-vission .mission-vission-wrapper .mission-card:last-child .content h2,
.mission-vission .mission-vission-wrapper .vision-card:last-child .content h2 {
  font-size: 26px;
  color: #fff;
}
.mission-vission .mission-vission-wrapper .mission-card:last-child .content p,
.mission-vission .mission-vission-wrapper .vision-card:last-child .content p {
  color: #fff;
}
.mission-vission .mission-vission-wrapper .mission-card:last-child .content .read--btn,
.mission-vission .mission-vission-wrapper .vision-card:last-child .content .read--btn {
  margin-top: 2rem;
}
.mission-vission .mission-vission-wrapper .mission-card:last-child .content .read--btn a,
.mission-vission .mission-vission-wrapper .vision-card:last-child .content .read--btn a {
  color: #fff;
  margin-top: 3rem;
}
.mission-vission .mission-vission-wrapper .mission-card .icon,
.mission-vission .mission-vission-wrapper .vision-card .icon {
  margin-bottom: 2rem;
  top: 0;
}
.mission-vission .mission-vission-wrapper .mission-card .icon img.mission-icon,
.mission-vission .mission-vission-wrapper .vision-card .icon img.mission-icon {
  max-height: 12rem;
}
.mission-vission .mission-vission-wrapper .mission-card .content h2,
.mission-vission .mission-vission-wrapper .vision-card .content h2 {
  font-size: 26px;
}
.mission-vission .mission-vission-wrapper .mission-card .content .read--btn,
.mission-vission .mission-vission-wrapper .vision-card .content .read--btn {
  margin-top: 2rem;
}
.mission-vission .mission-vission-wrapper .mission-card .content .read--btn a,
.mission-vission .mission-vission-wrapper .vision-card .content .read--btn a {
  color: #000;
  margin-top: 3rem;
}

.under-strip .strip-wrapper {
  text-transform: capitalize;
}
.under-strip .strip-wrapper .strip-card {
  padding: 0;
}
.under-strip .strip-wrapper .strip-card button {
  background: none;
  color: #ffb900;
  text-transform: capitalize !important;
  font-weight: lighter;
}

.navbar-expand-lg.bg-body-tertiary {
  background-color: #fff !important;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
  padding-top: 10px;
}
.navbar-expand-lg .navbar-collapse a.nav-link {
  padding-left: 35px;
}
@media screen and (max-width: 990px) {
  .navbar-expand-lg .navbar-collapse a.nav-link {
    padding-left: 0 !important;
  }
}

.desk.version4 .logo,
.desk.version4 .logo-title,
.desk.version4 .logo-title:before,
.desk.version4 .logo:before {
  background-color: #fff;
}

@media (min-width: 990px) {
  .version4 .header-top {
    background: #fff !important;
  }
  .header-top::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgb(202, 162, 105);
    -webkit-clip-path: polygon(27% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 30% 100%);
  }
  #html-body .navbar a.navbar-brand {
    position: absolute;
    top: -42px;
    z-index: 1212;
  }
  #html-body .navbar .navDropdown {
    transition: 1s;
  }
  #html-body .navbar .navDropdown:hover .dropdown-menu {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
    display: none;
  }
  #html-body .navbar .navDropdown .dropdown-menu {
    background: #212529;
    transform: translateY(40);
    opacity: 0;
    transition: 0.5s;
    display: block !important;
    visibility: hidden;
  }
  #html-body .navbar .navDropdown .dropdown-menu:hover {
    display: block !important;
    opacity: 1;
    top: 40px;
    transition: 0.2s ease-in;
    visibility: visible;
  }
  #html-body .navbar .navDropdown .dropdown-menu.show {
    opacity: 1;
    top: 40px;
    transition: 0.2s ease-in;
    visibility: visible;
  }
  #html-body .navbar .navDropdown .dropdown-menu a {
    color: #fff;
    font-size: 16px;
    padding: 0.6rem 1rem;
  }
  #html-body .navbar .navDropdown .dropdown-menu a.dropdown-item:hover {
    background: #0795b2;
  }
  #html-body .navbar .navDropdown .dropdown-menu a.dropdown-item:focus {
    background: #0795b2;
  }
}
.row-grid-unique {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.contactAccordion .accordion-button {
  background: #212529;
  font-size: 16px;
}
.contactAccordion .accordion-button::after {
  color: #fff !important;
  z-index: 12122;
}
.contactAccordion .accordion-button:focus {
  box-shadow: none;
}

.footer-column .footer-header h2:after {
  content: "";
  width: 30%;
  height: 2px;
  background: #fff;
  display: block;
}
.footer-column .footer-header h2:after:nth-child(2) {
  display: none;
}
.footer-column .footer-header h2:nth-child(2)::after {
  display: none !important;
}
.footer-column .contact-wrapper-address h4 {
  color: #fff;
}
.footer-column .contact-wrapper-address p {
  color: #fff !important;
}
.footer-column .contact-wrapper-address ul li div {
  display: inline-flex;
}
.footer-column .contact-wrapper-address ul li div i {
  color: #fff;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
  margin-right: 1rem;
}
.footer-column .contact-wrapper-address ul li div i.fas {
  color: #fff;
}

.footer-column:nth-child(2) {
  text-align: center;
}
@media screen and (max-width: 990px) {
  .footer-column:nth-child(2) {
    text-align: left;
  }
}
.footer-column:nth-child(2) h2::after {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
@media screen and (max-width: 990px) {
  .footer-column:nth-child(2) h2::after {
    position: static;
    left: auto;
    transform: inherit;
  }
}

footer .footer-main-wrapper .footer-header {
  color: #fff !important;
}
footer .footer-main-wrapper .footer-header p h3,
footer .footer-main-wrapper .footer-header p h5 {
  color: #fff;
  font-weight: lighter;
  font-size: 16px;
}
footer .footer-main-wrapper .footer-column .footer-header ul li {
  list-style: none;
  color: #fff !important;
}
footer .footer-main-wrapper .swiper-slide {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  color: #fff;
}

footer .footer-main-wrapper .footer-header {
  color: #fff !important;
}
footer .footer-main-wrapper .footer-header p h3,
footer .footer-main-wrapper .footer-header p h5 {
  color: #fff;
  font-weight: lighter;
  font-size: 16px;
}
footer .footer-main-wrapper .footer-column .footer-header ul li {
  list-style: none;
}
footer .footer-main-wrapper .footer-column .contact-wrapper-address ul {
  max-width: 300px;
}
footer .footer-main-wrapper .footer-column .contact-wrapper-address ul li {
  display: flex;
  align-items: baseline;
}
footer .footer-main-wrapper .swiper-slide {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  color: #fff;
}

.banner-swipering .BannerOvarlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3882352941);
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1111;
}
.banner-swipering .banner-content {
  z-index: 11212;
}

.modal-dialog .modal-body a.btn {
  padding: 0.2rem 0.6rem;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: #5a6268;
  border-color: #545b62;
  color: #fff;
  border-radius: 30px;
  min-width: 100% !important;
  margin-bottom: 10px;
}

.dropdown-menu {
  border: none;
  padding-left: 1rem;
  background: #212529;
  margin-left: 0 !important;
}
.dropdown-menu a {
  font-size: 14px;
  color: #fff;
}

@media (min-width: 990px) {
  .isSticky {
    position: fixed;
    right: 0;
    z-index: 12122;
    bottom: 0;
    width: 100%;
    transition: 0.5s ease-in;
  }
}
@media screen and (max-width: 990px) {
  .swiper {
    height: auto;
  }
}
.swiper .swiper-slide img {
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.contact-address-wrapper .contact-box::after {
  content: "";
  position: relative;
  width: 2px;
  height: 100%;
  background: #fff;
}

@media screen and (max-width: 482px) {
  .navbar .navbar-brand {
    max-width: 5rem;
  }
}

@media screen and (max-width: 482px) {
  .call-to-action .container.nz-clearfix span {
    font-size: 14px;
  }
}
.call-to-action .container.nz-clearfix .icon-bulb::before {
  font-size: 42px;
}

.inner-about.inner-content {
  padding: 75px;
}
@media screen and (max-width: 482px) {
  .inner-about.inner-content .nz-section {
    margin-top: 0 !important;
  }
}

.contactAccordion .contact-address-wrapper {
  margin: 2rem 0;
}
.contactAccordion .contact-address-wrapper:nth-child(3n+2) {
  border-left: 1px solid rgba(255, 255, 255, 0.2392156863);
  border-right: 1px solid rgba(255, 255, 255, 0.2392156863);
  padding-left: 2rem;
}
@media screen and (max-width: 990px) {
  .contactAccordion .contact-address-wrapper {
    border: none !important;
    padding-left: 0 !important;
  }
}
.contactAccordion .contact-address-wrapper:last-child {
  border-right: 0;
}/*# sourceMappingURL=mainstyle.css.map */